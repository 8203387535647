import React, { useState, useEffect } from 'react';
import {
  AppConstant,
  getAreaOfInterest,
  getCountries,
  getQualification,
  getSpecialization,
  getUserDetails,
  updateUserDetails,
  fetchBookmark,
  ImageConstant,
  updateProfilePicture,
  useAppDispatch,
  useAppSelector,
  getProfileCompletionPercentage,
  getTargetMilestone,
  GAMIFICATION_ACTION_ID,
  getPreferredLanguagesList,
  getProfilePicture,
  updateProfileImage,
  translate,
  userRegisteredEvents,
  getUserRegisteredEventList,
  fetchUserGamificationPointHistory,
  addOrUpdateUserGamificationPoints,
  GAMIFICATION_TYPE_UNIQUE_CODE,
  listGamificationPointDistribution,
  USER_GAMIFICATION_FILTER_RANGE_IN_DAYS,
  DEFAULT_MAX_CARDS_TO_SHOW_FOR_VIEW_ALL,
  getLoggedInUserInfo,
  ANALYTICS_PAGE_NAMES,
  getNotificationActionList,
  sendNotification,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  GAMIFICATION_ENABLED,
  PROFILE_PAGE_TABS_ENUM,
  PlatformNames,
  fetchUserRecognitionBadges,
  getAnalyticsUserDetails,
} from '@shalina-app/shared';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { appInsights } from '../../applicationInsight';
import styles from './profile.module.scss';
import {
  getTranslationFormat,
  storeAccessToken,
  storeData,
  storeProfilePic,
  userSignOut,
} from '../../utils';
import { BarLoader, Toast } from '../../components';
import {
  AchievementsPage,
  BookmarkPage,
  LanguagePreferencePage,
  ProfilePropsType,
  RegisteredEventsPage,
  RequestsPage,
  UpdateProfilePage,
} from '.';
import { AccountSettings } from './pages/accountSetting';
import { NotificationSetting } from './pages/notificationSetting';
import { MilestoneSection, UserInfoSection } from './components';
import {
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
  UserInterface,
} from '../../utils';
import { URLConstant } from '../../routes';
import {
  DEFAULT_TAKE,
  pageNames,
  PROFILE_PAGE_TABS,
} from '../../app.constants';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const tabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const Profile: React.FC<ProfilePropsType> = () => {
  const t = translate();
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;

  const location = useLocation();

  const requestNavigate = location?.state?.navigateFromHelpSupport;
  const tab = location?.state?.tab;
  const verificationFor = location?.state?.verificationFor;
  // const isVerifiedEmail = location?.state?.isVerifiedEmail;
  const mobile = location?.state?.updatedMobile;
  const email = location?.state?.updatedEmail;

  const isBookmarkLoading = useAppSelector(
    (state) => state?.profile?.bookmark?.isBookmarkLoading
  );
  // const isUpdateBookmarkLoading = useAppSelector(
  //   (state) => state?.profile?.updateBookmarks?.isUpdateBookmarkLoading
  // );
  const isUpdatePointLoading = useAppSelector(
    (state) => state?.notification?.isLoading
  );

  const userLoading = useAppSelector((state) => state?.user?.isLoading);
  const updateBookmarkRes = useAppSelector(
    (state) => state?.profile?.updateBookmarks?.updateBookmark
  );
  const isUpdateProfilePicLoading = useAppSelector(
    (state) => state?.profile?.updateProfilePic?.isUpdateProfilePicLoading
  );
  const qualificationList = useAppSelector(getQualification);
  const areaOfInterestList = useAppSelector(getAreaOfInterest);
  const specializationList = useAppSelector(getSpecialization);
  const countryOfPracticeList: any = useAppSelector(getCountries);
  const registeredEvents = useAppSelector(getUserRegisteredEventList);

  // const commonLoading = useAppSelector((state) => state?.common?.isLoading);
  // const authenticationLoading = useAppSelector(
  //   (state) => state?.authentication?.isLoading
  // );
  const notificationList = useAppSelector(getNotificationActionList);

  const [tabValue, setTabValue] = useState(0);
  const [apiCall, setApiCall] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  // const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [updatedMobile, setUpdatedMobile] = useState('');
  // const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState('');
  const [loader, setLoader] = useState(false);
  const [completedProfile, setCompletedProfile] = useState(false);

  const [profileData, setProfileData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [isSaveLoader, setIsSaveLoader] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);
  const isLoggedIn: boolean = retrieveData(STORAGE_CONSTANTS.IS_LOGGED_IN);

  // const refreshPage = () => window.location.reload();
  const languageList = useAppSelector(getPreferredLanguagesList);
  const gamificationPointDistributionData = useAppSelector(
    listGamificationPointDistribution
  );

  useEffect(() => {
    if (tab) {
      window.scrollTo(0, 0);
      setTabValue(tab);
    }
  }, [tab]);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(appInsights, 'pageView', ANALYTICS_PAGE_NAMES.MY_PROFILE);
    // adding here to avoid two loaders
    if (requestNavigate) {
      setTabValue(PROFILE_PAGE_TABS.REQUEST);
    } else {
      setApiCall(true);
    }
  }, []);

  useEffect(() => {
    if (apiCall) {
      loadUser();
      loadBookmarkApi();
      fetchRegisterEventApi();
      if (GAMIFICATION_ENABLED) {
        fetchGamificationData();
        loadRecognitionBadges();
      }
    }
  }, [apiCall]);

  const loadRecognitionBadges = () => {
    dispatch(fetchUserRecognitionBadges());
  }

  const fetchGamificationData = async () => {
    if (user) {
      const startDate = moment().subtract(
        USER_GAMIFICATION_FILTER_RANGE_IN_DAYS,
        'days'
      );
      const endDate = moment(startDate).add(
        USER_GAMIFICATION_FILTER_RANGE_IN_DAYS,
        'days'
      );
      const startDateFormat = moment(startDate).format('YYYY-MM-DD');
      const endDateFormat = moment(endDate).format('YYYY-MM-DD');
      dispatch(
        fetchUserGamificationPointHistory({
          skip: 0,
          take: DEFAULT_MAX_CARDS_TO_SHOW_FOR_VIEW_ALL,
          /**
           * Comment default prefetched start date and end date
           */
          // startDate: startDateFormat,
          // endDate: endDateFormat,
        })
      ).unwrap();
    }
  };

  const fetchRegisterEventApi = async () => {
    await dispatch(
      userRegisteredEvents({
        emailId: user?.emailId ? user.emailId : '',
        filterOptions: {
          interest: '',
          speciality: '',
          contentType: '',
          sort: '',
          take: DEFAULT_TAKE,
          skip: 0,
        },
      })
    );
  };

  useEffect(() => {
    if (qualificationList && qualificationList?.length > 0) {
      storeData(STORAGE_CONSTANTS.QUALIFICATION_LIST, qualificationList);
    }
    if (specializationList && specializationList?.length > 0) {
      storeData(STORAGE_CONSTANTS.SPECIALIZATION_LIST, specializationList);
    }
    if (areaOfInterestList && areaOfInterestList?.length > 0) {
      storeData(STORAGE_CONSTANTS.AREA_OF_INTEREST_LIST, areaOfInterestList);
    }
    if (countryOfPracticeList && countryOfPracticeList?.length > 0) {
      storeData(
        STORAGE_CONSTANTS.COUNTRY_OF_PRACTICE_LIST,
        countryOfPracticeList
      );
    }
  }, [
    qualificationList,
    specializationList,
    areaOfInterestList,
    countryOfPracticeList,
  ]);

  const loadUser = async () => {
    const response = await dispatch(getUserDetails()).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      dispatch(updateProfileImage(response?.data?.userPicture));
      setProfileData(response?.data);
      storeData(STORAGE_CONSTANTS.USER, response?.data);
    } else {
      setProfileData({});
    }
  };

  const loadBookmarkApi = async () => {
    await dispatch(fetchBookmark());
  };

  const sendNotificationForMilestoneUpdate = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.UPDATE_MILESTONE
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const updateGamificationPoints = async () => {
    if (isLoggedIn && GAMIFICATION_ENABLED) {
      const gamificationDataItem = gamificationPointDistributionData?.find(
        (item: any) =>
          item.field_unique_code ===
          GAMIFICATION_TYPE_UNIQUE_CODE.PROFILE_COMPLETION
      );
      if (gamificationDataItem && gamificationDataItem?.field_unique_code) {
        const payload = {
          field_unique_code: gamificationDataItem?.field_unique_code,
        };
        const response: any = await dispatch(addOrUpdateUserGamificationPoints(payload)).unwrap();
        if(response && response?.info) {
          sendNotificationForMilestoneUpdate()
        }
        loadUser();
      }
    }
  };

  const sendNotificationApiForProfileCompletion = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION
    )?.title;

    const req = {
      action: action,
      device: 'web',
    };
    // Hide Continue where you left feature
    await dispatch(sendNotification(req));
  };

  const handleSaveProfilePic = async (binaryData: any, removePic: boolean) => {
    if (removePic) {
      const req = {
        userPicture: '',
      };
      dispatch(updateProfileImage(ImageConstant.SOURCE.defaultUserPic));
      handleSaveProfileClick(req);
    } else {
      const response = await dispatch(
        updateProfilePicture(binaryData)
      ).unwrap();
      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        dispatch(updateProfileImage(response?.data?.userPicture));
        storeProfilePic(response?.data?.userPicture);
        const res = await dispatch(getUserDetails()).unwrap();
        if (res && res?.status === 'SUCCESS' && res?.statusCode === 200) {
          const storedUserPercentage = getProfileCompletionPercentage(user);
          const percentage = getProfileCompletionPercentage(res?.data);
          const userInfo = getAnalyticsUserDetails(res?.data);

          if (storedUserPercentage !== 100 && percentage === 100) {
            if (isLoggedIn) {
              logEvent(
                appInsights,
                'custom',
                CUSTOM_ANALYTICS_EVENT_NAMES.USER_PROFILE_COMPLETION,
                {
                  percentage: 100,
                  userEmail: userInfo?.emailId,
                  userMobile: userInfo?.mobile,
                  userName: userInfo?.name,
                  userQualification: userInfo?.qualification,
                  userInterest: userInfo?.areaOfInterest,
                  userSpecialization: userInfo?.specialization,
                  userCountryOfPractice: userInfo?.countryOfPractice,
                  userPreferredLanguage: userInfo?.preferredLanguage,
                  platform: PlatformNames.WEB,
                }
              );
            }
            sendNotificationApiForProfileCompletion();
            await updateGamificationPoints();
            setCompletedProfile(true);
            storeData(STORAGE_CONSTANTS.USER, res?.data);
          } else if (percentage !== 100) {
            if (isLoggedIn) {
              logEvent(
                appInsights,
                'custom',
                CUSTOM_ANALYTICS_EVENT_NAMES.USER_PROFILE_INCOMPLETE,
                {
                  percentage: percentage,
                  userEmail: userInfo?.emailId,
                  userMobile: userInfo?.mobile,
                  userName: userInfo?.name,
                  userQualification: userInfo?.qualification,
                  userInterest: userInfo?.areaOfInterest,
                  userSpecialization: userInfo?.specialization,
                  userCountryOfPractice: userInfo?.countryOfPractice,
                  userPreferredLanguage: userInfo?.preferredLanguage,
                  platform: PlatformNames.WEB,
                }
              );
            }
            storeData(STORAGE_CONSTANTS.USER, res?.data);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (completedProfile) {
      sendNotificationCall();
      setCompletedProfile(false);
    }
  }, [completedProfile]);

  const sendNotificationCall = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID.PROFILE_COMPLETION
    )?.title;

    const req = {
      action: action,
    };
    // await dispatch(updatePointsWithNotification(req));
    // refreshPage();
  };

  const handleSaveProfileClick = async (details: any) => {
    setIsSaveLoader(true);
    const req = {
      firstName: details?.firstName,
      lastName: details?.lastName,
      countryCode: details?.countryCode,
      mobileNumber: details?.mobileNumber,
      emailId: details?.emailId,
      qualification: details?.qualification,
      specialization: details?.specialization,
      country: details?.country,
      areaOfInterest: details?.areaOfInterest,
      userPicture: details?.userPicture,
    };
    // await setCheckStatusForUser(true);
    const response = await dispatch(updateUserDetails(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      setProfileData(response?.data);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
      storeData(STORAGE_CONSTANTS.USER, response?.data);
      setIsSaveLoader(false);
      if (response?.data?.token) {
        storeAccessToken(response?.data?.token);
      }
      const storedUserPercentage = getProfileCompletionPercentage(user);
      const percentage = getProfileCompletionPercentage(response?.data);
      const userInfo = getAnalyticsUserDetails(response?.data);
      if (storedUserPercentage !== 100 && percentage === 100) {
        if (isLoggedIn) {
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_PROFILE_COMPLETION,
            {
              percentage: 100,
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              platform: PlatformNames.WEB,
            }
          );
        }
        updateGamificationPoints();
        setCompletedProfile(true);
        storeData(STORAGE_CONSTANTS.USER, response?.data);
      } else if (percentage !== 100) {
        if (isLoggedIn) {
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_PROFILE_INCOMPLETE,
            {
              percentage: percentage,
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              platform: PlatformNames.WEB,
            }
          );
        }
        storeData(STORAGE_CONSTANTS.USER, response?.data);
      }
      storeProfilePic(response?.data?.userPicture);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response?.message));
      setIsSaveLoader(false);
      // setToastSubMessage("Your personal information have been modified.");
    }
  };

  const handleToastClose = () => {
    // if (isSuccess && isVerificationToast) {
    //   setOtp("");
    //   setModalOpen(true);
    //   setTimer(RESEND_TIMER);
    //   setResendEnable(false);
    // }
    setShowToast(false);
    // setIsVerificationToast(false);
  };

  const result = user?.milestoneToReach
    ?.slice()
    ?.sort((a: any, b: any) => a?.points - b?.points);
  // const isLastMileStone = result?.slice(-1)?.[0]?.tid === user?.milestone?.tid;

  const isLastMileStone = user?.milestoneToReach?.length === 0;
  const mileStone = getTargetMilestone(user?.milestone, user?.milestoneToReach);
  const userDetails = useAppSelector(getLoggedInUserInfo);

  const {
    BookmarkIcon,
    AchievementsIcon,
    TVIcon,
    RequestIcon,
    ContentIcon,
    BellOrangeIcon,
    AccountIcon,
    SignOutIcon,
    LanguageIcon,
  } = ImageConstant.SVG_ICONS;

  const defaultTabs = [
    [
      {
        id: PROFILE_PAGE_TABS.BOOKMARKS,
        enumId: PROFILE_PAGE_TABS_ENUM.BOOKMARKS,
        label: getTranslationFormat(AppConstant.PROFILE.BOOKMARKS_TAB_TEXT),
        icon: <BookmarkIcon />,
        component: (
          <BookmarkPage
            pageName={pageNames.Profile}
            pageRoute={urls.profilePage}
          />
        ),
      },
      {
        id: PROFILE_PAGE_TABS.ACHIEVEMENTS,
        enumId: PROFILE_PAGE_TABS_ENUM.ACHIEVEMENTS,
        label: getTranslationFormat(AppConstant.PROFILE.ACHIEVEMENTS_TAB_TEXT),
        icon: <AchievementsIcon />,
        // Commenting as mentioned: bug > #259
        component: (
          <AchievementsPage
            earnedPoints={userDetails?.points || user?.points}
            achieveMilestone={userDetails?.milestone || user?.milestone}
            milestoneToReach={
              userDetails?.milestoneToReach || user?.milestoneToReach
            }
            isLastMileStone={isLastMileStone}
          />
        ),
      },
      {
        id: PROFILE_PAGE_TABS.REGISTERED_EVENTS,
        enumId: PROFILE_PAGE_TABS_ENUM.REGISTERED_EVENTS,
        label: getTranslationFormat(
          AppConstant.PROFILE.REGISTERED_EVENTS_TAB_TEXT
        ),
        icon: <TVIcon />,
        component: (
          <RegisteredEventsPage
            pageName={pageNames.Profile}
            pageRoute={urls.profilePage}
            registeredEvents={registeredEvents}
          />
        ),
      },
      /* Hide for 1A */
      {
        id: PROFILE_PAGE_TABS.REQUEST,
        enumId: PROFILE_PAGE_TABS_ENUM.REQUEST,
        label: getTranslationFormat(AppConstant.PROFILE.REQUESTS_TAB_TEXT),
        icon: <RequestIcon />,
        component: <RequestsPage getState={location?.state} />, // Commenting as mentioned: #259
      },
    ],
    [
      {
        id: PROFILE_PAGE_TABS.NOTIFICATION_SETTING,
        enumId: PROFILE_PAGE_TABS_ENUM.NOTIFICATION_SETTING,
        label: getTranslationFormat(AppConstant.PROFILE.NOTIFICATION_TAB_TEXT),
        icon: <BellOrangeIcon />,
        component: <NotificationSetting />,
      },
      {
        id: PROFILE_PAGE_TABS.ACCOUNT_SETTING,
        enumId: PROFILE_PAGE_TABS_ENUM.ACCOUNT_SETTING,
        label: getTranslationFormat(
          AppConstant.PROFILE.ACCOUNT_SETTINGS_TAB_TEXT
        ),
        icon: <AccountIcon />,
        component: <AccountSettings />,
      },
      {
        id: PROFILE_PAGE_TABS.LANGUAGE_PREFERENCE,
        enumId: PROFILE_PAGE_TABS_ENUM.LANGUAGE_PREFERENCE,
        label: getTranslationFormat(
          AppConstant.PROFILE.LANGUAGE_PREFERENCE_TAB
        ),
        icon: <LanguageIcon />,
        component: <LanguagePreferencePage languageList={languageList} />,
      },
    ],
    [
      {
        id: PROFILE_PAGE_TABS.UPDATE_PROFILE,
        enumId: PROFILE_PAGE_TABS_ENUM.UPDATE_PROFILE,
        label: getTranslationFormat(
          AppConstant.PROFILE.UPDATE_PROFILE_TAB_TEXT
        ),
        icon: null,
        component: (
          <UpdateProfilePage
            verificationFor={verificationFor}
            updatedMobile={mobile}
            updatedEmail={email}
            setTabValue={setTabValue}
            handleSaveProfileClick={handleSaveProfileClick}
            profileData={profileData}
            isSaveLoader={isSaveLoader}
          />
        ),
      },
    ],
  ];

  const tabs = !GAMIFICATION_ENABLED
    ? defaultTabs?.map((tabGroup: any) =>
        tabGroup?.filter(
          (tab: any) => tab?.enumId !== PROFILE_PAGE_TABS_ENUM.ACHIEVEMENTS
        )
      )
    : defaultTabs;

  const handleUpdateProfile = () => {
    setTabValue(PROFILE_PAGE_TABS.UPDATE_PROFILE);
  };

  const handleSignOut = async () => {
    await userSignOut();

    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  const handleDicoverClick = async () => {
    await setTabValue(PROFILE_PAGE_TABS.ACHIEVEMENTS);
  };

  const userCallback = () => {
    loadUser();
  }

  // Commenting as mentioned: #259
  const setSideTab = (tabId: number) => {
    // if (tabId !== PROFILE_PAGE_TABS.ACHIEVEMENTS && tabId !== PROFILE_PAGE_TABS.REQUESTED_CONTENT && tabId !== PROFILE_PAGE_TABS.REQUEST) {
    setTabValue(tabId);
    // }
  };

  const completionPercentage = getProfileCompletionPercentage(user);
  // const profileImg = retrieveProfilePic();

  const profileImg = useAppSelector(getProfilePicture);
  return (
    <>
      {(userLoading || isUpdateProfilePicLoading) && <BarLoader />}
      <div className={styles.myProfilePage}>
        {/* userLoading */}
        {/* {(isUpdateProfilePicLoading) && (
          <CircularLoader size={50} thickness={4} />
        )} */}
        <div className='cust-container'>
          <div className={styles.profileTop}>
            <UserInfoSection
              userName={`${user?.firstName} ${user?.lastName}`}
              profilePic={profileImg}
              qualification={user?.qualification}
              specialization={user?.specialization}
              percentage={completionPercentage}
              isUpdateProfileVisible={
                tabValue === PROFILE_PAGE_TABS.UPDATE_PROFILE ? false : true
              }
              handleSaveProfilePic={handleSaveProfilePic}
              handleUpdateProfile={handleUpdateProfile}
              closePopup={closePopup}
            />
            {GAMIFICATION_ENABLED && (
              <MilestoneSection
                earnedPoints={userDetails?.points || user?.points}
                achieveMilestone={userDetails?.milestone || user?.milestone}
                milestoneToReach={
                  userDetails?.milestoneToReach || user?.milestoneToReach
                }
                handleDicoverClick={handleDicoverClick}
                isLastMileStone={isLastMileStone}
                userCallback={userCallback}
              />
            )}
          </div>
        </div>

        <div className='cust-container'>
          <div className={styles.profileTabContainer}>
            <div className={styles.tabCol}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label='Detail Tabs'
                className={styles.custTabs}
              >
                {tabs &&
                  tabs.map((tabsWrapper, index) =>
                    index !== 2 ? (
                      <div className={styles.tabWrapper}>
                        {tabsWrapper &&
                          tabsWrapper.map((tab: any) =>
                            tab?.id !== PROFILE_PAGE_TABS.UPDATE_PROFILE ? (
                              <Tab
                                {...tabProps(tab?.id)}
                                label={tab?.label}
                                onClick={() => setSideTab(tab?.id)}
                                className={
                                  tabValue === tab?.id ? styles.active : ''
                                }
                                icon={
                                  <span className={styles.tabicon}>
                                    {tab?.icon}
                                  </span>
                                }
                              />
                            ) : null
                          )}
                      </div>
                    ) : null
                  )}
                <div className={styles.tabWrapper}>
                  <Tab
                    label={getTranslationFormat(
                      AppConstant.PROFILE.SIGN_OUT_TAB_TEXT
                    )}
                    onClick={() => handleSignOut()}
                    icon={
                      <span className={styles.tabicon}>
                        <SignOutIcon />
                      </span>
                    }
                  />
                </div>
              </Tabs>
            </div>
            <div className={styles.tabContent}>
              {tabs &&
                tabs.map(
                  (tabsWrapper) =>
                    tabsWrapper &&
                    tabsWrapper.map((tab: any) => (
                      <CustomTabPanel value={tabValue} index={tab?.id}>
                        <div className={styles.tabContentBox}>
                          <h2 className={styles.head}>{tab?.label}</h2>
                          {tab?.component}
                        </div>
                      </CustomTabPanel>
                    ))
                )}
            </div>
          </div>
        </div>
        <Toast
          showToast={showToast}
          isSuccess={isSuccess}
          message={toastMessage}
          subMessage={toastSubMessage}
          handleToastClose={handleToastClose}
        />
      </div>
    </>
  );
};
