"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnalyticsUserDetails = exports.ShareModules = exports.CommunityStatus = exports.GuestString = exports.getAnalyticPageNameByCategory = exports.PlatformNames = exports.PageNames = exports.logEvent = exports.CUSTOM_ANALYTICS_EVENT_NAMES = exports.ANALYTICS_PAGE_NAMES = void 0;
const constants_utils_1 = require("./constants.utils");
exports.ANALYTICS_PAGE_NAMES = {
    KNOWLEDGE_HUB: 'Knowledge Hub Page',
    SHALINA_TV: 'Shalina TV Page',
    LEARNING_CENTER: 'Learning Center Page',
    HOME: 'Home Page',
    LISTING: 'Listing Page',
    MY_PROFILE: 'My Profile Page',
    SIGN_UP: 'Sign Up Page',
    SIGN_IN: 'Sign In Page',
    PROFILE_SETUP: 'Profile Setup Page',
};
exports.CUSTOM_ANALYTICS_EVENT_NAMES = {
    USER_PROFILE_COMPLETION: 'User Profile Completion',
    USER_PROFILE_INCOMPLETE: 'User Profile Incomplete',
    USER_REFER_A_FRIEND: 'User Refer a friend',
    USER_SHARE_CONTENT: 'User Share Content',
    USER_LIKED_CONTENT: 'User Like Content',
    USER_BOOKMARK_CONTENT: 'User Bookmark Content',
    USER_VISIT: 'User Visit',
    USER_REGISTRATION: 'User Registration',
    USER_LOGIN_MOBILE: 'User Login Mobile',
    USER_LOGIN_WEB: 'User Web Login',
    USER_DOWNLOAD_CONTENT: 'User Download Content',
    USER_REQUEST: 'User Request',
    USER_WEBINAR_REGISTRATION: 'User Webinar Registration',
    USER_MOST_VIEWED: 'User Most Viewed',
    USER_MOST_PAGE_VIEWED: 'User Most Page Viewed',
    USER_SESSION: 'User Session',
    API_RESPONSE_LOGS: 'API Response Logs',
    USER_COMMUNITY_STATUS: 'User Community Status',
    USER_SURVEY_SUBMIT: 'User Survey Submit',
};
const logEvent = (appInsight, eventType, eventName, properties) => {
    if (eventType === 'custom') {
        appInsight.trackEvent({
            name: eventName,
            properties: properties,
        });
    }
    else {
        appInsight.trackPageView({
            name: eventName,
        });
    }
};
exports.logEvent = logEvent;
exports.PageNames = {
    LISTING: 'Listing Page',
    LANDING: 'Landing Page',
    DETAIL: 'Detail Page',
};
exports.PlatformNames = {
    WEB: 'Web',
    ANDROID: 'Android',
    IOS: 'iOS',
};
const moduleName = {
    KnowledgeHub: 'Knowledge Hub',
    ShalinaTv: 'Shalina TV',
    LearningCenter: 'Learning Center',
};
const getAnalyticPageNameByCategory = (category) => {
    const pageMapping = {
        [constants_utils_1.API_CATEGORY_TYPES.KNOWLEDGE_HUB]: moduleName.KnowledgeHub,
        [constants_utils_1.API_CATEGORY_TYPES.SHALINA_TV]: moduleName.ShalinaTv,
        [constants_utils_1.API_CATEGORY_TYPES.LEARNING_CENTER]: moduleName.LearningCenter,
    };
    return pageMapping[category] || '';
};
exports.getAnalyticPageNameByCategory = getAnalyticPageNameByCategory;
exports.GuestString = 'Guest';
exports.CommunityStatus = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    REVOKED: 'revoked',
    LEFT: 'left',
};
exports.ShareModules = {
    COMMUNITY: 'community',
    SURVEY: 'survey',
    CONTENT: 'content',
};
const getAnalyticsUserDetails = (userInfo) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const qualification = (_b = (_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.qualification) === null || _a === void 0 ? void 0 : _a.map((qual) => qual === null || qual === void 0 ? void 0 : qual.title)) === null || _b === void 0 ? void 0 : _b.join(', ');
    const areaOfInterest = (_d = (_c = userInfo === null || userInfo === void 0 ? void 0 : userInfo.areaOfInterest) === null || _c === void 0 ? void 0 : _c.map((interest) => interest === null || interest === void 0 ? void 0 : interest.title)) === null || _d === void 0 ? void 0 : _d.join(', ');
    const specialization = (_f = (_e = userInfo === null || userInfo === void 0 ? void 0 : userInfo.specialization) === null || _e === void 0 ? void 0 : _e.map((spcl) => spcl === null || spcl === void 0 ? void 0 : spcl.title)) === null || _f === void 0 ? void 0 : _f.join(', ');
    const countryOfPractice = (_h = (_g = userInfo === null || userInfo === void 0 ? void 0 : userInfo.country) === null || _g === void 0 ? void 0 : _g.map((country) => country === null || country === void 0 ? void 0 : country.title)) === null || _h === void 0 ? void 0 : _h.join(', ');
    const userData = {
        emailId: (userInfo === null || userInfo === void 0 ? void 0 : userInfo.emailId) || '',
        mobile: `${userInfo === null || userInfo === void 0 ? void 0 : userInfo.countryCode} ${userInfo === null || userInfo === void 0 ? void 0 : userInfo.mobileNumber}` || '',
        name: `${userInfo === null || userInfo === void 0 ? void 0 : userInfo.firstName} ${userInfo === null || userInfo === void 0 ? void 0 : userInfo.lastName}` || '',
        qualification: qualification || '',
        areaOfInterest: areaOfInterest || '',
        specialization: specialization || '',
        countryOfPractice: countryOfPractice || '',
        preferredLanguage: ((_j = userInfo === null || userInfo === void 0 ? void 0 : userInfo.language) === null || _j === void 0 ? void 0 : _j.title) || '',
    };
    return userData;
};
exports.getAnalyticsUserDetails = getAnalyticsUserDetails;
