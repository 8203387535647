"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppConstant = void 0;
class AppConstant {
}
exports.AppConstant = AppConstant;
AppConstant.COMMON = {
    VIEW_ALL_CTA: "VIEW_ALL_CTA",
    DATE: "DATE",
    TIME: "TIME",
    DURATION: "DURATION",
    VIEWS: "VIEWS",
    BOOKMARKS_TEXT: "BOOKMARKS_TEXT",
    NO_CONTENT_FOUND: "NO_CONTENT_FOUND",
    ABOUT_US_TAB_TEXT: "ABOUT_US_TAB_TEXT",
    JOURNEY_TAB_TEXT: "JOURNEY_TAB_TEXT",
    EXPERTS_TAB_TEXT: "EXPERTS_TAB_TEXT",
    HELP_AND_SUPPORT_MENU: "HELP_AND_SUPPORT_MENU",
    TERMS_AND_CONDITIONS_MENU: "TERMS_AND_CONDITIONS_MENU",
    EXPLORE_TEXT: "EXPLORE_TEXT",
    QUICK_LINKS_TEXT: "QUICK_LINKS_TEXT",
    MOST_VIEWED_TEXT: "MOST_VIEWED_TEXT",
    PLAY_FULL_VIDEO_CTA: "PLAY_FULL_VIDEO_CTA",
    PLAY_TRAILER_VIDEO_CTA: "PLAY_TRAILER_VIDEO_CTA",
    REGISTER_NOW_CTA: "REGISTER_NOW_CTA",
    SHOWING_RESULTS_FOR_TEXT: "SHOWING_RESULTS_FOR_TEXT",
    NO_INTERNET_CONNECTION: "NO_INTERNET_CONNECTION",
    CONNECTION_RESTORED: "CONNECTION_RESTORED",
    NO_INTERNET_FOUND: "NO_INTERNET_FOUND",
    OFFLINE_CONTENT_VISIBILTY_CRITERIA: "OFFLINE_CONTENT_VISIBILTY_CRITERIA",
    OFFLINE_CONTENT_VISIBILTY_CRITERIA_GUEST: "OFFLINE_CONTENT_VISIBILTY_CRITERIA_GUEST",
    LINK_COPY_MESSAGE: "LINK_COPY_MESSAGE",
    LINK_COPY_ERROR_MESSAGE: "LINK_COPY_ERROR_MESSAGE",
    NO_RESULTS_FOUND_TEXT: "NO_RESULTS_FOUND_TEXT",
    OOPS_TEXT: "OOPS_TEXT",
    NO_RESULT_FOUND_SUB_MESSAGE: "NO_RESULT_FOUND_SUB_MESSAGE",
    STARTS: "STARTS",
    EXPIRES: "EXPIRES",
    LAUNCH_VIDEO: "LAUNCH_VIDEO",
    LAUNCH: "LAUNCH",
    WATCH_ON_DEMAND: "WATCH_ON_DEMAND",
    ERROR_MESSAGE_VALID_OTP: "ERROR_MESSAGE_VALID_OTP",
    SEARCH: "SEARCH",
    NO_SPEAKER_DETAILS: "NO_SPEAKER_DETAILS",
    LISTING_TEXT: "LISTING_TEXT",
    PDF_LOADING_FIELD: "PDF_LOADING_FIELD",
    COMMON_ERROR: "COMMON_ERROR",
    CLINICAL_CRITERIA_TAB: "CLINICAL_CRITERIA_TAB",
    DECISION_TREES_TAB: "DECISION_TREES_TAB",
    MEDICAL_EQUATIONS_TAB: "MEDICAL_EQUATIONS_TAB",
    READ_MORE_TEXT: "READ_MORE_TEXT",
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
    START_TIME: "START_TIME",
    END_TIME: "END_TIME",
    AUTHENTICATION_LOADER_TEXT: "AUTHENTICATION_LOADER_TEXT",
    ACCESSION_NUMBER: "ACCESSION_NUMBER",
    ISBN: "ISBN",
    SERIES_INFORMATION: "SERIES_INFORMATION",
    RESOURCE_TYPE: "RESOURCE_TYPE",
    OCLC: "OCLC",
    PRINT_ISBN: "PRINT_ISBN",
    ELECTRONIC_ISBN: "ELECTRONIC_ISBN",
    LANGUAGE: "LANGUAGE",
    ADDITIONAL_INFO: "ADDITIONAL_INFO",
    ENTRY_DATE: "ENTRY_DATE",
    UPDATE_CODE: "UPDATE_CODE",
    DOI: "DOI",
    SOURCE: "SOURCE",
    ISSUE: "ISSUE",
    PRINT_ISSN: "PRINT_ISSN",
    ELECTRONIC_ISSN: "ELECTRONIC_ISSN",
    AUTHOR_NAME: "AUTHOR_NAME",
    DOCUMENT_TYPE: "DOCUMENT_TYPE",
    PUBLICATION_DATE: "PUBLICATION_DATE",
    AUTHORED_BY: "AUTHORED_BY",
    PUBLISHED_BY: "PUBLISHED_BY",
    BY_TEXT: "BY_TEXT",
    REGISTER_WEBINAR_SUCCESSFULLY: "REGISTER_WEBINAR_SUCCESSFULLY",
    VOL_TEXT: "VOL_TEXT",
    NO_ADDITIONAL_INFO_AVALIABLE: "NO_ADDITIONAL_INFO_AVALIABLE",
    MEDICAL_NEWS_LISTING: "MEDICAL_NEWS_LISTING",
    MEDICAL_NEWS_DETAILS: "MEDICAL_NEWS_DETAILS",
    HELP_SUPPORT_TEXT: "HELP_SUPPORT_TEXT",
    SELECT_COUNTRY_CODE: "SELECT_COUNTRY_CODE",
    TODAY_TEXT: "TODAY_TEXT",
    YESTERDAY_TEXT: "YESTERDAY_TEXT",
    OLDER_TEXT: "OLDER_TEXT",
    BACK_CTA: "BACK_CTA",
    THANK_YOU_TEXT: "THANK_YOU_TEXT",
    RETURN_HOME_CTA: "RETURN_HOME_CTA",
    NO_REGISTERED_EVENTS: "NO_REGISTERED_EVENTS",
    NO_REGISTERED_EVENTS_SUBTEXT: "NO_REGISTERED_EVENTS_SUBTEXT",
    DISCOVER_LEARNING_CENTER_CTA: "DISCOVER_LEARNING_CENTER_CTA",
    PRACTICE_ESSENTIALS_MENU: "PRACTICE_ESSENTIALS_MENU",
    SURGERIES_AND_PROCEDURES_MENU: "SURGERIES_AND_PROCEDURES_MENU",
    JOURNALS_MENU: "JOURNALS_MENU",
    EBOOKS_MENU: "EBOOKS_MENU",
    CLINICAL_GUIDELINES_MENU: "CLINICAL_GUIDELINES_MENU",
    WEBINARS_MENU: "WEBINARS_MENU",
    MAGZINES_MENU: "MAGZINES_MENU",
    VIDEOS_MENU: "VIDEOS_MENU",
    THANK_YOU: "THANK_YOU",
    SURVEY_SUCCESS: "SURVEY_SUCCESS",
    SURVEY: "SURVEY",
    MEMBERSHIP_AND_BENEFITS: "MEMBERSHIP_AND_BENEFITS",
    POINTS: "POINTS",
    POINTS_CODE: "POINTS_CODE",
    BRONZE: "BRONZE",
    SILVER: "SILVER",
    GOLD: "GOLD",
    MEMBERSHIP_LABEL_TEXT: "MEMBERSHIP_LABEL_TEXT",
    HISTORY: "HISTORY",
    HOW_TO_EARN: "HOW_TO_EARN",
    POINTS_ACCUMULATED: "POINTS_ACCUMULATED",
    HOW_TO_SCORE: "HOW_TO_SCORE",
    SURVEY_MSG: "SURVEY_MSG",
    REQUEST_MODIFIED_MSG: "REQUEST_MODIFIED_MSG",
    REVOKE_MSG: "REVOKE_MSG",
    VIEW_REQUESTS: "VIEW_REQUESTS",
    DESCRIPTION_TEXT_ERROR: "DESCRIPTION_TEXT_ERROR",
    DESCRIPTION_TEXT_PLACEHOLDER: "DESCRIPTION_TEXT_PLACEHOLDER",
    CLOSE_EMPTY_MSG: "CLOSE_EMPTY_MSG",
    OPEN_EMPTY_MSG: "OPEN_EMPTY_MSG",
    SELECT_REQUEST_TYPE: "SELECT_REQUEST_TYPE",
    SELECT_CONTENT_TYPE: "SELECT_CONTENT_TYPE",
    SURVEY_DETAILS: "SURVEY_DETAILS",
    REQUESTED_CONTENT_DETAILS: "REQUESTED_CONTENT_DETAILS",
    REQUEST_REVOKED: "REQUEST_REVOKED",
    REVOKE_EMPTY_MSG: "REVOKE_EMPTY_MSG",
    NOTES: "NOTES",
    ENDS: "ENDS",
    ENTER_DESCRIPTION: "ENTER_DESCRIPTION",
    SELECT_OPTIONS: "SELECT_OPTIONS",
    SELECT: "SELECT",
    ENTER_INPUT: "ENTER_INPUT",
    EMPTY_SURVEY: "EMPTY_SURVEY",
    SEARCH_NEW_SUBMESSAGE: "SEARCH_NEW_SUBMESSAGE",
    APP_NOTIFICATION_TEXT: "APP_NOTIFICATION_TEXT",
    RECENT_ARTICLE_TEXT: "RECENT_ARTICLE_TEXT",
    ABOUT_US_SHALINA_MEDSPACE_TAB: "ABOUT_US_SHALINA_MEDSPACE_TAB",
    ABOUT_US_SHALINA_HEALTHCARE_TAB: "ABOUT_US_SHALINA_HEALTHCARE_TAB",
    FACING_ISSUE_TEXT: "FACING_ISSUE_TEXT",
    SESSION_EXPIRED_MESSAGE: "SESSION_EXPIRED_MESSAGE",
    ENABLE_EMAIL_OR_WHATSAPP_MESSAGE: "ENABLE_EMAIL_OR_WHATSAPP_MESSAGE",
    SHOWING: "SHOWING",
    OF_OVER: "OF_OVER",
    NO_DOWNLOADS: "NO_DOWNLOADS",
    NO_DOWNLOAD_DESCRIPTION: "NO_DOWNLOAD_DESCRIPTION",
    CONTENT: "CONTENT",
    YOUR: "YOUR",
    READ_SUCCESSFUL_DOWNLOAD: "READ_SUCCESSFUL_DOWNLOAD",
    DOWNLOAD_COMPLETE: "DOWNLOAD_COMPLETE",
    SECONDS_TEXT: "SECONDS_TEXT",
    NO_BOOKMARK_FOUND: "NO_BOOKMARK_FOUND",
    NO_BOOKMARK_FOUND_DESCRIPTION: "NO_BOOKMARK_FOUND_DESCRIPTION",
    NO_ACTIVE_WEBINARS_MESSAGE: "NO_ACTIVE_WEBINARS_MESSAGE",
    NO_ACTIVE_WEBINARS_SUB_MESSAGE: "NO_ACTIVE_WEBINARS_SUB_MESSAGE",
    NO_UPCOMING_WEBINARS_MESSAGE: "NO_UPCOMING_WEBINARS_MESSAGE",
    NO_UPCOMING_WEBINARS_SUB_MESSAGE: "NO_UPCOMING_WEBINARS_SUB_MESSAGE",
    SHARE_TITLE: "SHARE_TITLE",
    EXTRA_POINTS_TEXT: "EXTRA_POINTS_TEXT",
    UPDATE_APP: "UPDATE_APP",
    UPDATE_APP_MESSAAGE: "UPDATE_APP_MESSAAGE",
    UPDATE_NOW: "UPDATE_NOW",
    EXIT_MESSAGE: "EXIT_MESSAGE",
    SUPPORT_UPLOAD_IMAGE_HELP_TEXT: "SUPPORT_UPLOAD_IMAGE_HELP_TEXT",
    REQUEST_INPROGRESS_EMPTY_MSG: "REQUEST_INPROGRESS_EMPTY_MSG",
    REQUEST_INPROGRESS: "REQUEST_INPROGRESS",
    BADGES_EARNED: "BADGES_EARNED",
    NO_BADGES: "NO_BADGES",
    HOW_TO_EARN_BADGES: "HOW_TO_EARN_BADGES",
    CONGRATULATIONS_TEXT: "CONGRATULATIONS_TEXT",
    REDEEM_POINTS_CTA: "REDEEM_POINTS_CTA",
    YOUR_CURRENT_POINTS: "YOUR_CURRENT_POINTS",
    REDEEM_CTA: "REDEEM_CTA",
    POINT_REDEEM_REQUEST: "POINT_REDEEM_REQUEST",
    REDEEM_POINT_SHOULD_LESS_THAN_YOUR_POINT: "REDEEM_POINT_SHOULD_LESS_THAN_YOUR_POINT",
    REDEEM_MINIMUM_POINT_ERROR: "REDEEM_MINIMUM_POINT_ERROR",
    REDEEM_POINTS: "REDEEM_POINTS",
    SURVEY_NOT_STARTED_MESSAGE: "SURVEY_NOT_STARTED_MESSAGE",
    SHARE_LINK_COPIED: "SHARE_LINK_COPIED"
};
AppConstant.AUTHENTICATION_PAGE = {
    SIGN_UP_FORM_WEB_HEADING: "SIGN_UP_FORM_WEB_HEADING",
    VERIFICATION_CODE_SENT_TO_TITLE: "VERIFICATION_CODE_SENT_TO_TITLE",
    OTP_INPUT_PLACEHOLDER: "OTP_INPUT_PLACEHOLDER",
    MOBILE_NUMBER_TEXT: "MOBILE_NUMBER_TEXT",
    REQUEST_REFERRAL_CODE_TEXT: "REQUEST_REFERRAL_CODE_TEXT",
    PROFILE_SETUP_TEXT: "PROFILE_SETUP_TEXT",
    TERMS_AND_CONDITION_TEXT: "TERMS_AND_CONDITION_TEXT",
    ACCESS_REQUEST_SENT: "ACCESS_REQUEST_SENT",
    THANK_FOR_INTEREST_IN_SHALINA: "THANK_FOR_INTEREST_IN_SHALINA",
    ACCESS_HAS_BEEN_RECEIVED: "ACCESS_HAS_BEEN_RECEIVED",
    REQUEST_REFERRAL_CODE_PROCESSED_ACKNOWLEDGEMENT: "REQUEST_REFERRAL_CODE_PROCESSED_ACKNOWLEDGEMENT",
    START_YOUR_JOURNEY: "START_YOUR_JOURNEY",
    SIGN_IN_CTA: "SIGN_IN_CTA",
    SIGN_UP_CTA: "SIGN_UP_CTA",
    CONTINUE_CTA: "CONTINUE_CTA",
    INTEREST_SELECTED_TEXT: "INTEREST_SELECTED_TEXT",
    TERMS_AND_CONDITION_CONCENT_PART1: "TERMS_AND_CONDITION_CONCENT_PART1",
    TERMS_AND_CONDITION_CONCENT_PART2: "TERMS_AND_CONDITION_CONCENT_PART2",
    TERMS_AND_CONDITION_CONCENT_PART3: "TERMS_AND_CONDITION_CONCENT_PART3",
    TERMS_CONDITIONS_VALIDATION_TEXT: "TERMS_CONDITIONS_VALIDATION_TEXT",
    ALREADY_HAVE_ACCOUNT_TEXT: "ALREADY_HAVE_ACCOUNT_TEXT",
    DIDNOT_HAVE_ACCOUNT_TEXT: "DIDNOT_HAVE_ACCOUNT_TEXT",
    GET_VERIFICATION_CODE_ON_TEXT: "GET_VERIFICATION_CODE_ON_TEXT",
    I_HAVE_TEXT: "I_HAVE_TEXT",
    PREFERRED_LANGUAGE: "PREFERRED_LANGUAGE",
    EMAIL_TEXT: "EMAIL_TEXT",
    WHATSAPP_TEXT: "WHATSAPP_TEXT",
    LAST_NAME_TEXT: "LAST_NAME_TEXT",
    FIRST_NAME_TEXT: "FIRST_NAME_TEXT",
    OTP_SOURCE_EMAIL_TEXT: "OTP_SOURCE_EMAIL_TEXT",
    OTP_SOURCE_SMS_TEXT: "OTP_SOURCE_SMS_TEXT",
    OTP_SOURCE_WHATSAPP_TEXT: "OTP_SOURCE_WHATSAPP_TEXT",
    VERIFICATION_CODE_MEDIUM_REGISTRATION_TEXT: "VERIFICATION_CODE_MEDIUM_REGISTRATION_TEXT",
    VERIFICATION_CODE_MEDIUM_REFERRAL_TEXT: "VERIFICATION_CODE_MEDIUM_REFERRAL_TEXT",
    VERIFICATION_CODE_MEDIUM_REQUEST_REFERRAL_TEXT: "VERIFICATION_CODE_MEDIUM_REQUEST_REFERRAL_TEXT",
    VERIFICATION_TEXT: "VERIFICATION_TEXT",
    VERIFICATION_CODE_SENT_TO_TEXT: "VERIFICATION_CODE_SENT_TO_TEXT",
    ENTER_VERIFICATION_CODE_TEXT: "ENTER_VERIFICATION_CODE_TEXT",
    TIME_REMAINING_TEXT: "TIME_REMAINING_TEXT",
    RESEND_OTP_TEXT: "RESEND_OTP_TEXT",
    RESEND_CODE: "RESEND_CODE",
    START_YOUR_JOURNEY_CTA: "START_YOUR_JOURNEY_CTA",
    SELECT_INTEREST_TEXT: "SELECT_INTEREST_TEXT",
    NEXT_CTA: "NEXT_CTA",
    COUNTRY_OF_PRACTICE_TEXT: "COUNTRY_OF_PRACTICE_TEXT",
    QUALIFICATION_TEXT: "QUALIFICATION_TEXT",
    SPECIALTY: "SPECIALTY",
    SEARCH_TEXT: "SEARCH_TEXT",
    SKIP_CTA: "SKIP_CTA",
    HOME_SIGN_UP_TEXT: "HOME_SIGN_UP_TEXT",
    HOME_SIGN_UP_HEADER_TEXT: "HOME_SIGN_UP_HEADER_TEXT",
    VERIFY_TEXT: "VERIFY_TEXT",
    SIGN_In_TO_CONTINUE: "SIGN_In_TO_CONTINUE",
    OR_TEXT: "OR_TEXT",
    CODE_TEXT: "CODE_TEXT",
    SEC_TEXT: "SEC_TEXT",
    REGISTRATION_COMPLETE_TEXT: "REGISTRATION_COMPLETE_TEXT",
    PROFILE_REGISTRATION_TEXT: "PROFILE_REGISTRATION_TEXT",
    SELECT_COUNTRY_TEXT: "SELECT_COUNTRY_TEXT",
    EMAIL_AND_MOBILE_NUMBER_MISMATCHED: "EMAIL_AND_MOBILE_NUMBER_MISMATCHED",
    USER_ALREADY_EXISTS: "USER_ALREADY_EXISTS",
    SIGN_UP_TO_CONTINUE: "SIGN_UP_TO_CONTINUE",
    SELECT_COUNTRY_OF_PRACTICE: "SELECT_COUNTRY_OF_PRACTICE",
    SELECT_QUALIFICATION: "SELECT_QUALIFICATION",
    SELECT_SPECIALIZATION: "SELECT_SPECIALIZATION"
};
AppConstant.HEADER = {
    MORE_MENU: "MORE_MENU",
    LOGO_ALT_TEXT: "LOGO_ALT_TEXT",
    HOME_MENU: "HOME_MENU",
    KNOWLEDGE_HUB_MENU: "KNOWLEDGE_HUB_MENU",
    SHALINA_TV_MENU: "SHALINA_TV_MENU",
    LEARNING_CENTER_MENU: "LEARNING_CENTER_MENU",
    COMMUNITIES_MENU: "COMMUNITIES_MENU",
    RESOURCES_MENU: "RESOURCES_MENU",
    SURVEYS_MENU: "SURVEYS_MENU",
    MEDICAL_CALCULATOR_MENU: "MEDICAL_CALCULATOR_MENU",
    DOSAGE_CALCULATOR_MENU: "DOSAGE_CALCULATOR_MENU",
    RISK_DIESEASE_ASSESSMENT_MENU: "RISK_DIESEASE_ASSESSMENT_MENU",
    VITAL_MONITORING_APP_MENU: "VITAL_MONITORING_APP_MENU",
    TREATMENT_ALGORITHM_MENU: "TREATMENT_ALGORITHM_MENU",
    ABOUT_US_MENU: "ABOUT_US_MENU",
    HELP_SUPPORT_MENU: "HELP_SUPPORT_MENU",
    NOTIFICATIONS_TEXT: "NOTIFICATIONS_TEXT",
    NO_NEW_NOTIFICATION_TEXT: "NO_NEW_NOTIFICATION_TEXT",
    NO_NOTIFICATION_ERROR_TEXT: "NO_NOTIFICATION_ERROR_TEXT",
    BACK_TO_HOME_CTA: "BACK_TO_HOME_CTA",
    MY_PROFILE: "MY_PROFILE",
    EDIT_PROFILE_TEXT: "EDIT_PROFILE_TEXT",
    DEACTIVATE_ACCOUNT_TEXT: "DEACTIVATE_ACCOUNT_TEXT",
    REFER_A_FRIEND: "REFER_A_FRIEND",
    SURVEYS_TEXT: "SURVEYS_TEXT",
    KNOWLEDGE_HUB_MENU_TAB: "KNOWLEDGE_HUB_MENU_TAB",
    LEARNING_CENTER_MENU_TAB: "LEARNING_CENTER_MENU_TAB"
};
AppConstant.FOOTER = {
    FOOTER_ABOUT_SHALINA_TEXT: "FOOTER_ABOUT_SHALINA_TEXT",
    FOOTER_FOLLOW_US_TEXT: "FOOTER_FOLLOW_US_TEXT",
    DOWNLOAD_TEXT: "DOWNLOAD_TEXT",
    DOWNLOAD_SUB_TEXT: "DOWNLOAD_SUB_TEXT",
    FOOTER_LICENCE_TEXT: "FOOTER_LICENCE_TEXT",
    DOWNLOAD_OPTIONS: "DOWNLOAD_OPTIONS",
    GO_TO_DOWNLOADS_CTA: "GO_TO_DOWNLOADS_CTA",
};
AppConstant.HOME_PAGE = {
    THANK_YOU: "THANK_YOU",
    FEATURED_TEXT: "FEATURED_TEXT",
    ASK_TO_JOIN_TEXT: "ASK_TO_JOIN_TEXT",
    RESET_FILTER: "RESET_FILTER",
    APPLY_FILTER: "APPLY_FILTER",
    FILTER: "FILTER",
    CONTENT_TYPE: "CONTENT_TYPE",
    FILTER_BY: "FILTER_BY",
    SORT_BY: "SORT_BY",
    COMPLETE_PROFILE_EARN_POINTS_TEXT: "COMPLETE_PROFILE_EARN_POINTS_TEXT",
    COMPLETE_PROFILE_CTA: "COMPLETE_PROFILE_CTA",
    COMPLETED_TEXT: "COMPLETED_TEXT",
    TRENDING_NOW: "TRENDING_NOW",
    NEWLY_ADDED: "NEWLY_ADDED",
    EXPERTS: "EXPERTS",
    EXPLORE_COMMUNITIES: "EXPLORE_COMMUNITIES",
    MEDICAL_NEWS: "MEDICAL_NEWS",
    EXPLORE_MORE: "EXPLORE_MORE",
    RECOMMENDED_FOR_YOU: "RECOMMENDED_FOR_YOU",
    CONTINUE_WHERE_LEFT_OFF: "CONTINUE_WHERE_LEFT_OFF",
    TAKE_SURVEY: "TAKE_SURVEY",
    POSTED_BY: "POSTED_BY",
    RECENT_SEARCH: "RECENT_SEARCH",
    RELATED_WEBINARS: "RELATED_WEBINARS",
    NO_TEXT: "NO_TEXT",
    DATA_TEXT: "DATA_TEXT",
    FOUND_TEXT: "FOUND_TEXT",
    NO_DATA_FOUND_MESSAGE: "NO_DATA_FOUND_MESSAGE",
    NO_JOURNAL_FOUND_MESSAGE: "NO_JOURNAL_FOUND_MESSAGE",
    MEMBERS: "MEMBERS",
    MEMBER: "MEMBER",
    SOMETHING_NEW_TODAY: "SOMETHING_NEW_TODAY",
    NO_FEATURED_CONTENT: "NO_FEATURED_CONTENT",
    NO_EXPLORE_COMMUNITIES: "NO_EXPLORE_COMMUNITIES",
    NO_CONTINUE_LEFT: "NO_CONTINUE_LEFT",
    NO_RECOMMENDED_LEFT: "NO_RECOMMENDED_LEFT",
    NO_TRENDING_NOW: "NO_TRENDING_NOW",
    NO_MEDICAL_NEWS: "NO_MEDICAL_NEWS",
    HELLO: "HELLO",
    NEWS_FEEDS: "NEWS_FEEDS",
    FEEDS: "FEEDS",
    START_DATE: "START_DATE",
    END_DATE: "END_DATE",
    SUBMIT_CTA: "SUBMIT_CTA",
    BACK_CTA: "BACK_CTA",
    CONTINUE_WHERE_YOU_LEFT_OFF: "CONTINUE_WHERE_LEFT_OFF",
    APPLY_CTA: "APPLY_CTA",
    BEGIN_SURVEY: "BEGIN_SURVEY",
    EXPIRES: "EXPIRES",
    REQUEST_TYPE: "REQUEST_TYPE",
    DESCRIPTION_TEXT: "DESCRIPTION_TEXT",
    REQUESTS: "REQUESTS",
    REQUEST_RAISED: "REQUEST_RAISED",
    REQUEST_CLOSED: "REQUEST_CLOSED",
    REQUEST_CONTENT_DETAIL_TEXT: "REQUEST_CONTENT_DETAIL_TEXT",
    UPLOAD_ATTACHMENT: "UPLOAD_ATTACHMENT",
    ATTACHMENT: "ATTACHMENT",
    GOLD_MEMBER_CONTENT_TEXT: "GOLD_MEMBER_CONTENT_TEXT",
    RAISE_REQUEST_TEXT: "RAISE_REQUEST_TEXT",
    GO_TO_REQUEST_LIST_CTA: "GO_TO_REQUEST_LIST_CTA",
    RAISE_REQUEST_MESSAGE: "RAISE_REQUEST_MESSAGE",
    RAISE_REQUEST_CONTENT_MESSAGE: "RAISE_REQUEST_CONTENT_MESSAGE",
    MODIFY_REQUEST_MESSAGE: "MODIFY_REQUEST_MESSAGE",
    UPLOAD_IMAGE_TEXT: "UPLOAD_IMAGE_TEXT",
    IMAGE_MAX_MB_ERROR_TEXT: "IMAGE_MAX_MB_ERROR_TEXT",
    REQUEST_CONTENT_CTA: "REQUEST_CONTENT_CTA",
    REVOKE_CTA: "REVOKE_CTA",
    MODIFY_CTA: "MODIFY_CTA",
    REVOKE_REQUEST: "REVOKE_REQUEST",
    REVOKE_REQUEST_QUESTION: "REVOKE_REQUEST_QUESTION",
    I_AM_SURE_TEXT: "I_AM_SURE_TEXT",
    SUBMIT_REQUEST: "SUBMIT_REQUEST",
    MODIFY_REQUEST: "MODIFY_REQUEST",
    SURE: "SURE",
    REQUEST_DETAILS: "REQUEST_DETAILS",
    NO_SURVEY_FOUND_MESSAGE: "NO_SURVEY_FOUND_MESSAGE",
    TO_START_EXPLORING: "TO_START_EXPLORING",
    REGISTER_LOGIN: "REGISTER_LOGIN"
};
AppConstant.KNOWLEDGE_HUB_PAGE = {
    KNOWLEDGE_HUB_TEXT: "KNOWLEDGE_HUB_TEXT",
    EXPLORE_MORE_SHALINA_TV: "EXPLORE_MORE_SHALINA_TV",
    EXPLORE_MORE_LEARNING_CENTER: "EXPLORE_MORE_LEARNING_CENTER",
    EXPLORE_MORE_COMMUNITIES: "EXPLORE_MORE_COMMUNITIES",
    BROWSE_ARTICLES: "BROWSE_ARTICLES",
    BROWSE_NEWS: "BROWSE_NEWS",
    BROWSE_RESOURCES: "BROWSE_RESOURCES",
    DISCOVER_MORE: "DISCOVER_MORE",
    DISCOVER_SHALINA_TV_HEADER: "DISCOVER_SHALINA_TV_HEADER",
    DISCOVER_LEARNING_CENTER_HEADER: "DISCOVER_LEARNING_CENTER_HEADER",
    DISCOVER_COMMUNITIES_HEADER: "DISCOVER_COMMUNITIES_HEADER",
    DISCOVER_SHALINA_TV_SUB_SENTENCE: "DISCOVER_SHALINA_TV_SUB_SENTENCE",
    DISCOVER_LEARNING_CENTER_SUB_SENTENCE: "DISCOVER_LEARNING_CENTER_SUB_SENTENCE",
    DISCOVER_COMMUNITIES_SUB_SENTENCE: "DISCOVER_COMMUNITIES_SUB_SENTENCE",
    DYNAMIC_KH_LISTING_TITLE_PART_1: "DYNAMIC_KH_LISTING_TITLE_PART_1",
    DYNAMIC_KH_LISTING_TITLE_PART_2: "DYNAMIC_KH_LISTING_TITLE_PART_2",
    DISCOVER_TEXT_FOR_KNOWLEDGE_HUB_MENU: "DISCOVER_TEXT_FOR_KNOWLEDGE_HUB_MENU",
    DISCOVER_TEXT_FOR_KNOWLEDGE_HUB_MENU_SUB_SENTENCE: "DISCOVER_TEXT_FOR_KNOWLEDGE_HUB_MENU_SUB_SENTENCE",
    EXPLORE_MORE_KNOWLEDGE_HUB: "EXPLORE_MORE_KNOWLEDGE_HUB",
    DESCRIPTION_TAB_TEXT: "DESCRIPTION_TAB_TEXT",
    SPEAKER_DETAILS_TAB_TEXT: "SPEAKER_DETAILS_TAB_TEXT",
    BROWSE_TEXT: "BROWSE_TEXT",
    RSS_FEED: "RSS_FEED",
    FEEDS: "FEEDS",
    RSS_FEED_DETAILS: "RSS_FEED_DETAILS",
    DOWNLOAD_SUCCESSFULLY: "DOWNLOAD_SUCCESSFULLY",
    DOWNLOAD_FAILED: "DOWNLOAD_FAILED",
    DOWNLOAD_FAILED_SUB_MESSAGE: "DOWNLOAD_FAILED_SUB_MESSAGE",
    DOWNLOAD_NO_FILE_AVAILABLE: "DOWNLOAD_NO_FILE_AVAILABLE",
    DOWNLOAD_RESOURCE: "DOWNLOAD_RESOURSE",
    RELATED_RESOURCES_TEXT: "RELATED_RESOURCES_TEXT",
    START_READING: "START_READING",
    BOOK_DETAILS_TAB: "BOOK_DETAILS_TAB",
    BOOK_DESCRIPTION_TAB: "BOOK_DESCRIPTION_TAB",
    PUBLISHER: "PUBLISHER",
    SELECT_PUBLISHER: "SELECT_PUBLISHER",
    VOLUME: "VOLUME",
    NO_OF_PAGES: "NO_OF_PAGES",
    SPECIALIZATION: "SPECIALIZATION",
    ISSN: "ISSN",
    PUBLICATION_YEAR: "PUBLICATION_YEAR",
    NO_DESCRIPTION_AVAILABLE: "NO_DESCRIPTION_AVAILABLE",
    RELATED: "RELATED",
    NO_BOOK_DETALS_AVAILABLE: "NO_BOOK_DETALS_AVAILABLE",
    NO_VIDEO_DETALS_AVAILABLE: "NO_VIDEO_DETALS_AVAILABLE",
    WEBINARS: "WEBINARS",
    ALL_TAB_TEXT: "ALL_TAB_TEXT",
    UPCOMING_TAB_TEXT: "UPCOMING_TAB_TEXT",
    ACTIVE_TAB_TEXT: "ACTIVE_TAB_TEXT",
    PAST_TAB_TEXT: "PAST_TAB_TEXT",
    ARTICLES: "ARTICLES",
    NO_ARTICLES_FOUND: "NO_ARTICLES_FOUND",
    DISCOVER_LEARNING_CENTER: "DISCOVER_LEARNING_CENTER",
    VIEW_ATTACHMENT: "VIEW_ATTACHMENT"
};
AppConstant.ERROR = {
    ERROR_TEXT: 'ERROR',
    OTP_INVALID_OR_EXPIRED: "OTP_INVALID_OR_EXPIRED",
    REGISTRATION_CODE_NOT_FOUND: "REGISTRATION_CODE_NOT_FOUND",
    PROFILE_PICTURE_UPLOAD_FAILED: "PROFILE_PICTURE_UPLOAD_FAILED",
    INCORRECT: "INCORRECT",
    ENTER_VALID_EMAIL: "ENTER_VALID_EMAIL",
    ENTER_VALID_MOBILE: "ENTER_VALID_MOBILE",
    ENTER_VALID_MODE: "ENTER_VALID_MODE",
    ENTER_VALID_FIRST_NAME: "ENTER_VALID_FIRST_NAME",
    ENTER_VALID_LAST_NAME: "ENTER_VALID_LAST_NAME",
    ENTER_VALID_PREFFERED_LANG: "ENTER_VALID_PREFFERED_LANG",
    ENTER_VALID_OPTION: "ENTER_VALID_OPTION",
    ENTER_VALID_CODE: "ENTER_VALID_CODE",
    OTP_SENT_SUCCESS: "OTP_SENT_SUCCESS",
    OTP_SENT_FAILED: "OTP_SENT_SUCCESS",
    USER_NOT_FOUND: "USER_NOT_FOUND",
    PLEASE_SELECT_QUALIFICATION: "PLEASE_SELECT_QUALIFICATION",
    PLEASE_SELECT_SPECIALIZATION: "PLEASE_SELECT_SPECIALIZATION",
    PLEASE_SELECT_COUNTRY_OF_PRACTICE: "PLEASE_SELECT_COUNTRY_OF_PRACTICE",
    OTP_REQUIRED: "OTP_REQUIRED",
    ENTER_VALID_OTP: "ENTER_VALID_OTP",
    SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
    PLEASE_SELECT_AREA_OF_INTEREST: "PLEASE_SELECT_AREA_OF_INTEREST",
    MOBILE_VALIDATION_FOR_START_ZERO: "MOBILE_VALIDATION_FOR_START_ZERO",
    ACCOUNT_BLOCKED_MESSAGE: "ACCOUNT_BLOCKED_MESSAGE"
};
AppConstant.PROFILE = {
    TOTAL_EARNED_TEXT: "TOTAL_EARNED_TEXT",
    SILVER_TEXT: "SILVER_TEXT",
    PROFILE_SECTION_DISCOVER_TEXT: "PROFILE_SECTION_DISCOVER_TEXT",
    TOTAL_TEXT: "TOTAL_TEXT",
    POINTS_SHORTCODE: "POINTS_SHORTCODE",
    PROFILE_COMPLETION_TEXT: "PROFILE_COMPLETION_TEXT",
    UPDATE_PROFILE_CTA: "UPDATE_PROFILE_CTA",
    EDIT_PROFILE_PICTURE_TEXT: "EDIT_PROFILE_PICTURE_TEXT",
    UPLOAD_CTA: "UPLOAD_CTA",
    REMOVE_CTA: "REMOVE_CTA",
    CANCEL_CTA: "CANCEL_CTA",
    SAVE_CTA: "SAVE_CTA",
    BOOKMARKS_TAB_TEXT: "BOOKMARKS_TAB_TEXT",
    ACHIEVEMENTS_TAB_TEXT: "ACHIEVEMENTS_TAB_TEXT",
    REGISTERED_EVENTS_TAB_TEXT: "REGISTERED_EVENTS_TAB_TEXT",
    REQUESTS_TAB_TEXT: "REQUESTS_TAB_TEXT",
    REQUESTED_CONTENT_TAB_TEXT: "REQUESTED_CONTENT_TAB_TEXT",
    NOTIFICATION_TAB_TEXT: "NOTIFICATION_TAB_TEXT",
    ACCOUNT_SETTINGS_TAB_TEXT: "ACCOUNT_SETTINGS_TAB_TEXT",
    SIGN_OUT_TAB_TEXT: "SIGN_OUT_TAB_TEXT",
    UPDATE_PROFILE_TAB_TEXT: "UPDATE_PROFILE_TAB_TEXT",
    PERSONAL_TAB_TEXT: "PERSONAL_TAB_TEXT",
    PROFESSIONAL_TAB_TEXT: "PROFESSIONAL_TAB_TEXT",
    INTEREST_TAB_TEXT: "INTEREST_TAB_TEXT",
    SELECTED_LANGUAGE_TEXT: "SELECTED_LANGUAGE_TEXT",
    LANGUAGE_PREFERENCE_TAB: "LANGUAGE_PREFERENCE_TAB",
    ACCOUNT_DETAILS_TAB_TEXT: "ACCOUNT_DETAILS_TAB_TEXT",
    DOWNLOADS_TAB_TEXT: "DOWNLOADS_TAB_TEXT",
    LANGUAGE_PREFERNCES_TEXT: "LANGUAGE_PREFERNCES_TEXT",
    MY_ACCOUNT_TEXT: "MY_ACCOUNT_TEXT",
    REFER_DOCTOR_TEXT: "REFER_DOCTOR_TEXT",
    APP_VERSION_TEXT: "APP_VERSION_TEXT",
    VERIFY_CTA: "VERIFY_CTA",
    VERIFIED: "VERIFIED",
    START_EXPLORING_CTA: "START_EXPLORING_CTA",
    ADD_BOOKMARKS_TEXT: "ADD_BOOKMARKS_TEXT",
    NO_BOOKMARK_DESCRIPTION: "NO_BOOKMARK_DESCRIPTION",
    SETTINGS_TEXT: "SETTINGS_TEXT",
    IN_APP_NOTIFICATION_TEXT: "IN_APP_NOTIFICATION_TEXT",
    PUSH_NOTIFICATION_TEXT: "PUSH_NOTIFICATION_TEXT",
    EMAIL_NOTIFICATION_TEXT: "EMAIL_NOTIFICATION_TEXT",
    SMS_NOTIFICATION_TEXT: "SMS_NOTIFICATION_TEXT",
    WHATS_APP_NOTIFICATION_TEXT: "WHATS_APP_NOTIFICATION_TEXT",
    DELETE_ACCOUNT_TEXT: "DELETE_ACCOUNT_TEXT",
    REASON_TEXT: "REASON_TEXT",
    DELETE_WARN_TEXT: "DELETE_WARN_TEXT",
    KEEP_ACTIVE_TEXT: "KEEP_ACTIVE_TEXT",
    DEACTIVATE_TEXT: "DEACTIVATE_TEXT",
    REFER_DOCTOR_EARN_TEXT: "REFER_DOCTOR_EARN_TEXT",
    REFER_DOCTOR_MSG: "REFER_DOCTOR_MSG",
    REFER_DOCTOR: "REFER_DOCTOR",
    SHARE_LINK: "SHARE_LINK",
    MILESTONE_TEXT: "MILESTONE_TEXT",
    CAUTION_TEXT: "CAUTION_TEXT",
    ACCOUNT_WARN: "ACCOUNT_WARN",
    YES_DEACTIVATE_TEXT: "YES_DEACTIVATE_TEXT",
    SHARE_TEXT: "SHARE_TEXT",
    REFERAL_MSG: "REFERAL_MSG",
    REFERAL_MSG_WITHOUT_GAMIFICATION: "REFERAL_MSG_WITHOUT_GAMIFICATION",
    ALL_TEXT: "ALL_TEXT",
    REMOVE_TEXT: "REMOVE_TEXT",
    REFER_DOCTORS: "REFER_DOCTORS",
    ACTION_TEXT: "ACTION_TEXT",
    REMOVE_FROM_DOWNLOAD: "REMOVE_FROM_DOWNLOAD",
    PROFILE_PHOTO_TEXT: "PROFILE_PHOTO_TEXT",
    CAMERA_TEXT: "CAMERA_TEXT",
    UPLOAD_TEXT: "UPLOAD_TEXT",
    REMOVE_PHOTO_TEXT: "REMOVE_PHOTO_TEXT",
    REFERRAL_CODE: "REFERRAL_CODE",
    DELETE_MSG_TEXT: "DELETE_MSG_TEXT",
    DELETE_MSG_CONTINUE: "DELETE_MSG_CONTINUE",
    NO_SPEAKER_DETAIL_FOUND: "NO_SPEAKER_DETAIL_FOUND",
    SHARE_REFERRAL_CODE_LINK_MSG: "SHARE_REFERRAL_CODE_LINK_MSG",
    SHARE_REFERRAL_CODE_LINK_TEXT_CONTINUE: "SHARE_REFERRAL_CODE_LINK_TEXT_CONTINUE",
    HI_TEXT: "HI_TEXT",
    SHARE_CONTENT_MSG: "SHARE_CONTENT_MSG",
    CONTENT_TEXT: "CONTENT_TEXT",
    CLICK_TEXT: "CLICK_TEXT",
    SHARE_CONTENT_REMAINING_MSG: "SHARE_CONTENT_REMAINING_MSG",
    PUBLISHED_BY: "PUBLISHED_BY",
    SURVEY_FORM_SUBMITTED: "SURVEY_FORM_SUBMITTED",
    SELECT_YOUR_MEDICAL_QUALIFICATION: "SELECT_YOUR_MEDICAL_QUALIFICATION",
    SELECT_YOUR_SPECIALIZATION: "SELECT_YOUR_SPECIALIZATION",
    ENTER_YOUR_EMAIL: "ENTER_YOUR_EMAIL",
    ENTER_FIRST_NAME: "ENTER_FIRST_NAME",
    ENTER_LAST_NAME: "ENTER_LAST_NAME",
    ENTER_MOBILE_NUMBER: "ENTER_MOBILE_NUMBER",
    ENTER_VERIFICATION_CODE: "ENTER_VERIFICATION_CODE",
    ENTER_VALID_REGISTRATION_CODE: "ENTER_VALID_REGISTRATION_CODE",
    DELETE_DOWNLOAD_TEXT: "DELETE_DOWNLOAD_TEXT",
    DELETE_TEXT: "DELETE_TEXT",
    DISCARD_CHANGES: "DISCARD_CHANGES",
    VERIFY_DISCARD_CHANGES: "VERIFY_DISCARD_CHANGES",
    SUCCESSFUL_REFERRAL: "SUCCESSFUL_REFERRAL",
    EARN: "EARN",
    REFERRAL_REMAINING_MSG: "REFERRAL_REMAINING_MSG",
    REFER_DOCTOR_EARN_TEXT_WITHOUT_GAMIFICATION: "REFER_DOCTOR_EARN_TEXT_WITHOUT_GAMIFICATION",
    RECOGNITION: "RECOGNITION",
    SURVEY_SHARE_CONTENT_REMAINING_MSG: "SURVEY_SHARE_CONTENT_REMAINING_MSG"
};
AppConstant.COMMUNITY = {
    DISCUSSIONS_TAB: "DISCUSSIONS_TAB",
    EXPLORE_COMMUNITIES_TAB: "EXPLORE_COMMUNITIES_TAB",
    COMMUNITY_REQUEST_TAB: "COMMUNITY_REQUEST_TAB",
    COMMUNITY_ANSWER_QUESTIONS_TAB: "COMMUNITY_ANSWER_QUESTIONS_TAB",
    MY_COMMUNITIES_HEADING: "MY_COMMUNITIES_HEADING",
    COMMUNITIES_VIEW_ALL_CTA: "COMMUNITIES_VIEW_ALL_CTA",
    RECOMMENDED_HEADING: "RECOMMENDED_HEADING",
    COMMUNITY_ASK_TO_JOIN_CTA: "COMMUNITY_ASK_TO_JOIN_CTA",
    COMMUNITY_REJECT_CTA: "COMMUNITY_REJECT_CTA",
    COMMUNITY_ACCEPT_CTA: "COMMUNITY_ACCEPT_CTA",
    COMMUNITY_REVOKE_CTA: "COMMUNITY_REVOKE_CTA",
    EXPERTS_HEADING: "EXPERTS_HEADING",
    REQUEST_FILTER_SENT_TEXT: "REQUEST_FILTER_SENT_TEXT",
    REQUEST_FILTER_RECEIVED_TEXT: "REQUEST_FILTER_RECEIVED_TEXT",
    EXPLORE_COMMUNITIES_CTA: "EXPLORE_COMMUNITIES_CTA",
    WELCOME_TO_COMMUNITIES: "WELCOME_TO_COMMUNITIES",
    JOIN_COMMUNITY_TEXT: "JOIN_COMMUNITY_TEXT",
    POSTED: "POSTED",
    VIEW_MORE_COMMENTS_CTA: "VIEW_MORE_COMMENTS_CTA",
    VIEW_MORE_DISCUSSIONS_CTA: "VIEW_MORE_DISCUSSIONS_CTA",
    VIEW_ALL_POPUP_QUESTIONS_TEXT: "VIEW_ALL_POPUP_QUESTIONS_TEXT",
    VIEW_ALL_POPUP_COMMENTS_TEXT: "VIEW_ALL_POPUP_COMMENTS_TEXT",
    COMMUNITY_GUIDELINE_CONTINUE_CTA: "COMMUNITY_GUIDELINE_CONTINUE_CTA",
    COMMUNITY_DETAIL_DISCUSSIONS_TAB: "COMMUNITY_DETAIL_DISCUSSIONS_TAB",
    COMMUNITY_DETAIL_ABOUT_TAB: "COMMUNITY_DETAIL_ABOUT_TAB",
    COMMUNITY_DETAIL_MEMBERS_TAB: "COMMUNITY_DETAIL_MEMBERS_TAB",
    COMMUNITY_DETAIL_RESOURCES_TAB: "COMMUNITY_DETAIL_RESOURCES_TAB",
    COMMUNITY_DETAIL_NO_DISCUSSION_AVAILABLE: "COMMUNITY_DETAIL_NO_DISCUSSION_AVAILABLE",
    COMMUNITY_GUIDELINE_ACKNOWLEDGEMENT: "COMMUNITY_GUIDELINE_ACKNOWLEDGEMENT",
    COMMUNITY_GUIDELINE_HEADING: "COMMUNITY_GUIDELINE_HEADING",
    COMMUNITY_FILTER_CATEGORY_TAB: "COMMUNITY_FILTER_CATEGORY_TAB",
    COMMUNITY_FILTER_COUNTRY_TAB: "COMMUNITY_FILTER_COUNTRY_TAB",
    COMMUNITY_RESOURCES_FILE_NAME: "COMMUNITY_RESOURCES_FILE_NAME",
    COMMUNITY_RESOURCES_SIZE: "COMMUNITY_RESOURCES_SIZE",
    COMMUNITY_EXPERT_FLAG: "COMMUNITY_EXPERT_FLAG",
    COMMUNITY_QUESTION_REPLY_CTA: "COMMUNITY_QUESTION_REPLY_CTA",
    COMMUNITY_QUESTION_CANCEL_CTA: "COMMUNITY_QUESTION_CANCEL_CTA",
    AWAITING_FOR_APPROVAL_TEXT: "AWAITING_FOR_APPROVAL_TEXT",
    REPLY_INPUT_PLACEHOLDER_TEXT: "REPLY_INPUT_PLACEHOLDER_TEXT",
    TOPIC_COMMENT_PLACEHOLDER_TEXT: "TOPIC_COMMENT_PLACEHOLDER_TEXT",
    QA_COMMENT_PLACEHOLDER_TEXT: "QA_COMMENT_PLACEHOLDER_TEXT",
    QUESTION_SUBMISSION_CLOSES_ON_TEXT: "QUESTION_SUBMISSION_CLOSES_ON_TEXT",
    EVENT_TEXT: "EVENT_TEXT",
    EVENT_ENDS: "EVENT_ENDS",
    SEE_MORE_TEXT: "SEE_MORE_TEXT",
    SEE_LESS_TEXT: "SEE_LESS_TEXT",
    REJECTED_TEXT: "REJECTED_TEXT",
    QUESTION_REVOKE_CTA: "QUESTION_REVOKE_CTA",
    LEAVE_COMMUNITY: "LEAVE_COMMUNITY",
    LEAVE_COMMUNITY_CONFIRMATION_TEXT: "LEAVE_COMMUNITY_CONFIRMATION_TEXT",
    NO_SENT_REQUEST: "NO_SENT_REQUEST",
    NO_RECEIVED_REQUEST: "NO_RECEIVED_REQUEST",
    NO_DISCUSSTION_AVAILABLE: "NO_DISCUSSTION_AVAILABLE",
    NO_DESCRIPTION_AVAILABLE_FOR_COMMUNITY: "NO_DESCRIPTION_AVAILABLE_FOR_COMMUNITY",
    NO_MEMBERS_FOUND_FOR_COMMUNITY: "NO_MEMBERS_FOUND_FOR_COMMUNITY",
    NO_RESOURCES_FOUND_FOR_COMMUNITY: "NO_RESOURCES_FOUND_FOR_COMMUNITY",
    FAILED_TO_LOAD_COMMUNITY_RESOURCE_FILE: "FAILED_TO_LOAD_COMMUNITY_RESOURCE_FILE",
    REPLY_ATTACHMENT_HELP_TEXT: "REPLY_ATTACHMENT_HELP_TEXT",
    JOIN_COMMUNITY_REQUEST_SUCCESS: "JOIN_COMMUNITY_REQUEST_SUCCESS",
    JOIN_COMMUNITY_REQUEST_FAILED: "JOIN_COMMUNITY_REQUEST_FAILED",
    REVOKE_COMMUNITY_REQUEST_SUCCESS: "REVOKE_COMMUNITY_REQUEST_SUCCESS",
    REVOKE_COMMUNITY_REQUEST_FAILED: "REVOKE_COMMUNITY_REQUEST_FAILED",
    ACCEPT_COMMUNITY_REQUEST_SUCCESS: "ACCEPT_COMMUNITY_REQUEST_SUCCESS",
    ACCEPT_COMMUNITY_REQUEST_FAILED: "ACCEPT_COMMUNITY_REQUEST_FAILED",
    REJECT_COMMUNITY_REQUEST_SUCCESS: "REJECT_COMMUNITY_REQUEST_SUCCESS",
    REJECT_COMMUNITY_REQUEST_FAILED: "REJECT_COMMUNITY_REQUEST_FAILED",
    NO_COMMUNITIES_FOUND: "NO_COMMUNITIES_FOUND",
    LEAVE_COMMUNITY_SUCCESSFULLY: "LEAVE_COMMUNITY_SUCCESSFULLY",
    REPLY_ATTACHMENT_LARGE_ERROR_MESSAGE: "REPLY_ATTACHMENT_LARGE_ERROR_MESSAGE",
    NO_POINTS_EARNED_MESSAGE: "NO_POINTS_EARNED_MESSAGE",
    QUESTION_REVOKE_SUCCESSFULLY: "QUESTION_REVOKE_SUCCESSFULLY",
    GUIDELINE_TEXT: "GUIDELINE_TEXT",
    COMMUNITY_TEXT: "COMMUNITY_TEXT",
    SHARE_COMMUNITY_REMAINING_MSG: "SHARE_COMMUNITY_REMAINING_MSG",
    GUIDELINE_ACCURACY_OF_INFORMATION: "GUIDELINE_ACCURACY_OF_INFORMATION",
    GUIDELINE_ACCURACY_OF_INFORMATION_DESCRIPTION: "GUIDELINE_ACCURACY_OF_INFORMATION_DESCRIPTION",
    GUIDELINE_PRIVACY: "GUIDELINE_PRIVACY",
    GUIDELINE_PRIVACY_DESCRIPTION: "GUIDELINE_PRIVACY_DESCRIPTION",
    GUIDELINE_INTELLECTUAL_PROPERTY: "GUIDELINE_INTELLECTUAL_PROPERTY",
    GUIDELINE_INTELLECTUAL_PROPERTY_DESCRIPTION: "GUIDELINE_INTELLECTUAL_PROPERTY_DESCRIPTION",
    GUIDELINE_MODERATION: "GUIDELINE_MODERATION",
    GUIDELINE_MODERATION_DESCRIPTION: "GUIDELINE_MODERATION_DESCRIPTION",
    GUIDELINE_CONDUCT: "GUIDELINE_CONDUCT",
    GUIDELINE_CONDUCT_DESCRIPTION: "GUIDELINE_CONDUCT_DESCRIPTION",
    GUIDELINE_CHANGES_TO_COMMUNITY_POLICY: "GUIDELINE_CHANGES_TO_COMMUNITY_POLICY",
    GUIDELINE_CHANGES_TO_COMMUNITY_POLICY_DESCRIPTION: "GUIDELINE_CHANGES_TO_COMMUNITY_POLICY_DESCRIPTION"
};
