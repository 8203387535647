import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppConstant,
  getContactModes,
  getCountryCode,
  ImageConstant,
  sendOtpApi,
  SEND_OTP_ACTION_TYPE,
  translate,
  useAppDispatch,
  useAppSelector,
  getContactModeLabelById,
  OTP_VERIFICATION_MEDIUM,
  getCurrentLanguageForWeb,
  getPreferredLanguagesList,
  LANGUAGE_UNIQUE_ID,
  registrationCode,
  ANALYTICS_PAGE_NAMES,
  logEvent,
  RESTRICT_REGISTRATION_REFERRAL_CODE_FIELD,
} from '@shalina-app/shared';
import { Checkbox, FormControlLabel, SelectChangeEvent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { appInsights } from '../../../../applicationInsight';
import {
  ButtonComponent,
  CircularLoader,
  Dropdown,
  HyperLink,
  Input,
  MobileInput,
  Modal,
  RadioGroupComponent,
  Toast,
} from '../../../../components';
import styles from './signUp.module.scss';
import {
  capitalizeFirstLetter,
  countryCodeValidation,
  dropDownValidation,
  emailValidation,
  eventType,
  inputTextValidation,
  mobileStartZeroValidation,
  mobileValidation,
  radioGroupValidation,
  registrationCodeValidation,
  STORAGE_CONSTANTS,
  storeData,
  useNavigatePageUtils,
} from '../../../../utils';
import { URLConstant } from '../../../../routes';
import { pageNames } from '../../../../app.constants';

export const SignUpPage: React.FC<any> = () => {
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const t = translate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const statePathName = location?.state?.pathName;
  const rssContentData = location?.state?.rssContentData;

  const referralCode = location?.state?.referralCode || '';

  const localStorageLang = useAppSelector(getCurrentLanguageForWeb);
  // const localStorageLang = retrieveData(STORAGE_CONSTANTS.SELECTED_LANGUAGE);

  const countryList = useAppSelector(getCountryCode);
  const contactModes = useAppSelector(getContactModes);
  const authenticationLoading = useAppSelector(
    (state) => state?.authentication?.isLoading
  );
  // const commonLoading = useAppSelector((state) => state?.common?.isLoading);
  const languageList = useAppSelector(getPreferredLanguagesList);

  const preferredLanguagesList =
    languageList &&
    languageList.map((item: any) => ({
      label: item?.name,
      value: item?.tid,
      field_unique_code: item?.field_unique_code,
    }));
  // const mobileLength = useAppSelector(getMaxMobileLength);

  const [selectedCodeType, setSelectedCodeType] = useState('');
  const [selectedVerificationMedium, setSelectedVerificationMedium] =
    useState('');
  const [codeTextField, setCodeTextField] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [nextCtaEnable, setNextCtaEnable] = useState(false);
  const [codeText, setCodeText] = useState('');
  const [termsCheck, setTermsCheck] = useState();
  const [countryCode, setCountryCode] = useState('');
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [mobileLength, setMobileLength] = useState('');
  const [isRegistrationCodeDisabled, setIsRegistrationCodeDisabled] =
    useState(false);
  const [isRegistrationCodeLoading, setIsRegistrationCodeLoading] =
    useState(false);
  const [countryFlag, setCountryFlag] = useState('');
  const [isReferralFlow, setIsReferralFlow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCtaAction, setModalCtaAction] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(appInsights, 'pageView', ANALYTICS_PAGE_NAMES.SIGN_UP);
    loadApi();
  }, []);

  useEffect(() => {
    if (contactModes && !selectedVerificationMedium) {
      const whatsAppDefault = contactModes?.find(
        (mode: { label: string; value: string }) =>
          mode?.label?.includes('Whatsapp')
      );
      setSelectedVerificationMedium(whatsAppDefault?.value);
    }
  }, [contactModes]);

  useEffect(() => {
    if (countryCode) {
      const country = countryList?.find(
        (item: any) => item?.value === countryCode
      );
      setMobileLength(country?.mobileLength);
      fetchRegistrationCode();
      setCountryFlag(country?.icons);
    }
  }, [countryList, countryCode]);

  useEffect(() => {
    if (selectedCodeType !== 'requestReferralCode') {
      fetchRegistrationCode();
    } else {
      setCodeText('');
    }
  }, [selectedCodeType]);

  useEffect(() => {
    if (languageList && languageList?.length > 0) {
      if (localStorageLang) {
        const currentLanguageSelected = languageList?.find(
          (lang: any) =>
            lang?.field_unique_code?.toLowerCase() ===
            localStorageLang?.toLowerCase()
        );
        setSelectedLanguage(currentLanguageSelected?.tid);
      } else {
        const defaultLanguage: any = languageList?.find(
          (item: any) => item?.field_unique_code === LANGUAGE_UNIQUE_ID?.ENGLISH
        );
        setSelectedLanguage(defaultLanguage?.tid);
      }
    }
  }, [languageList, localStorageLang]);

  const loadApi = async () => {
    // await dispatch(getCountryCodeList());
    // await dispatch(getPreferredLanguageList());
  };

  useEffect(() => {
    if (countryList && countryList?.length > 0) {
      const initialCountryCode = countryList?.[0]?.value;
      setCountryCode(initialCountryCode);
      storeData(STORAGE_CONSTANTS.COUNTRY_LIST, countryList);
    }
  }, [countryList]);

  useEffect(() => {
    if (referralCode) {
      setCodeText(referralCode);
      setCodeTextField(true);
      setSelectedCodeType('registrationCode');
      setIsCodeInvalid(false); // Resetting validation status
      setIsReferralFlow(true);
    } else {
      setIsReferralFlow(false);
    }
  }, []);

  const fetchRegistrationCode = async () => {
    // if (!RESTRICT_REGISTRATION_REFERRAL_CODE_FIELD) {
    if (!referralCode && mobileNumber && emailId) {
      setIsRegistrationCodeLoading(true);
      const response = await dispatch(
        registrationCode({
          countryCode: countryCode,
          mobileNumber: mobileNumber,
          emailId: emailId,
        })
      );
      setIsRegistrationCodeLoading(false);
      if (response?.payload?.data?.registrationCode) {
        setCodeText(response?.payload?.data?.registrationCode);
        setIsCodeInvalid(false);
        setIsRegistrationCodeDisabled(true);
        setCodeTextField(true);
        setSelectedCodeType('registrationCode');
      } else if (selectedCodeType === 'requestReferralCode') {
        setCodeText('');
        setIsCodeInvalid(false);
        setIsRegistrationCodeDisabled(false);
        setCodeTextField(false);
      } else {
        setCodeText('');
        setIsCodeInvalid(false);
        setIsRegistrationCodeDisabled(false);
        // setCodeTextField(false);
        // setSelectedCodeType('');
      }
    }
    // }
  };

  const handleCodeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const codeType = (event.target as HTMLInputElement).value;
    setSelectedCodeType(codeType);
    if (codeType === 'registrationCode') {
      setCodeTextField(true);
    } else {
      const settingRegistrationCode = isRegistrationCodeDisabled
        ? codeText
        : '';
      setCodeText(settingRegistrationCode);
      setIsCodeInvalid(false);
      setCodeTextField(false);
    }
  };

  const handleVerificationMediumChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const modeId = (event.target as HTMLInputElement).value;
    setSelectedVerificationMedium(modeId);
  };

  const selectedModeLabel = useAppSelector(
    getContactModeLabelById(selectedVerificationMedium)
  );

  const handleNext = async () => {
    const req = {
      payload: {
        countryCode: countryCode,
        mobileNumber: mobileNumber,
        emailId: emailId,
        mode: selectedModeLabel?.toLowerCase(),
        registrationCode: codeText,
      },
      params: SEND_OTP_ACTION_TYPE.SIGN_UP_ACTION,
    };

    const response = await dispatch(sendOtpApi(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
      // setToastSubMessage("OTP was sent successfully");
      setNextCtaEnable(false);

      const navigateDetails = {
        stateParams: {
          pathName: statePathName,
          rssContentData: rssContentData,
          sourcePage: pageNames.SignUp,
          nextCta: t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_CTA),
          isReferral: isReferralFlow,
          formData: {
            firstName: firstName,
            lastName: lastName,
            mobile: mobileNumber,
            country: countryCode,
            email: emailId,
            mode: selectedModeLabel?.toLowerCase(),
            language: selectedLanguage,
            registrationCode: codeText,
            requestForReferralCode:
              selectedCodeType === 'requestReferralCode' || false,
            isTermsConditionAccepted: termsCheck || false,
            otpSent:
              selectedModeLabel?.toLowerCase() ===
              OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
                ? emailId
                : `${countryCode} ${mobileNumber}`,
          },
        },
        isDynamicUrl: false,
      };
      navigatePageUtils(urls.verificationPage, navigateDetails);
    } else {
      if (response?.message === 'REGISTRATION_CODE_NOT_FOUND') {
        setIsCodeInvalid(true);
      }
      setNextCtaEnable(false);
      // setShowToast(true);
      // setIsSuccess(false);
      setModalOpen(true);
      if(response && response?.status === 'ERROR' && response?.statusCode === 403 && response?.message === 'USER_BLOCKED') {
        setToastMessage(t(AppConstant.ERROR.ACCOUNT_BLOCKED_MESSAGE))
        setModalCtaAction('Okay')
      }else{
        setToastMessage(t(response?.message));
        setModalCtaAction('')
      }
      // setToastMessage("OTP Sent Failed!");
      // setToastSubMessage("Please check your credentials and try again.");
    }
  };

  const handleToastClose = () => {
    setShowToast(false);
  };

  const switchToSignIn = () => {
    const navigateDetails = {
      isDynamicUrl: false,
      stateParams: {
        pathName: statePathName,
        rssContentData: rssContentData,
      },
    };
    navigatePageUtils(urls.signInPage, navigateDetails);
  };

  const handleTermAction = () => {
    setTermsModalOpen(true);
  };

  const handleTermsModalClose = () => {
    setTermsModalOpen(false);
  };

  const verificationCodeObj = [
    {
      label: t(
        AppConstant.AUTHENTICATION_PAGE
          .VERIFICATION_CODE_MEDIUM_REGISTRATION_TEXT
      ),
      value: 'registrationCode',
    },
    // {
    //   label: t(
    //     AppConstant.AUTHENTICATION_PAGE.VERIFICATION_CODE_MEDIUM_REFERRAL_TEXT),
    //   value: "referralCode",
    // },
    {
      label: t(
        AppConstant.AUTHENTICATION_PAGE
          .VERIFICATION_CODE_MEDIUM_REQUEST_REFERRAL_TEXT
      ),
      value: 'requestReferralCode',
    },
  ];

  const codeFieldValidation = () => {
    let validated = false;
    if (selectedCodeType === 'registrationCode') {
      if (codeText && codeText?.trim()?.length > 0) {
        validated = true;
      }
    } else if (selectedCodeType === 'requestReferralCode') {
      validated = true;
    }

    return validated;
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const checkCondition = () => {
    if (
      firstName &&
      !inputTextValidation(firstName) &&
      lastName &&
      !inputTextValidation(lastName) &&
      emailId &&
      !emailValidation(emailId) &&
      mobileNumber &&
      !mobileValidation(mobileNumber, mobileLength) &&
      !mobileStartZeroValidation(mobileNumber) &&
      countryCode &&
      !countryCodeValidation(countryCode) &&
      selectedVerificationMedium &&
      !radioGroupValidation(selectedVerificationMedium) &&
      termsCheck
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (RESTRICT_REGISTRATION_REFERRAL_CODE_FIELD) {
      if (checkCondition()) {
        setNextCtaEnable(true);
      } else {
        setNextCtaEnable(false);
      }
    } else {
      if (checkCondition() && selectedCodeType && codeFieldValidation()) {
        setNextCtaEnable(true);
      } else {
        setNextCtaEnable(false);
      }
    }
  }, [
    firstName,
    lastName,
    emailId,
    mobileNumber,
    countryCode,
    selectedCodeType,
    codeText,
    selectedVerificationMedium,
    termsCheck,
  ]);

  const onLogoClick = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  const handleHelpSupport = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.helpSupport, navigateDetails);
  };

  const handleCodeChange = (value: string) => {
    setCodeText(value);
    setIsCodeInvalid(false);
  };

  const handleCountryCodeChange = async (value: any) => {
    setCountryCode(value);
  };
  const { AuthenticationBgIcon } = ImageConstant.SVG_ICONS;

  const onModalCtaPress = () => {
    setModalOpen(false)
    setToastMessage('')
    setModalCtaAction('')
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.helpSupport, navigateDetails);
  }

  return (
    <>
      <div className={styles.signUpWrapper}>
        <div className={styles.authBg}>
          <AuthenticationBgIcon />
        </div>
        <div className='cust-container'>
          <div className={styles.signUplogo}>
            <img
              src={ImageConstant.SOURCE.ShalinaMedspaceLogo}
              alt='logo'
              className={styles.logo}
              onClick={onLogoClick}
            />
            <div className={styles.signInTop}>
              <span>
                {t(AppConstant.AUTHENTICATION_PAGE.ALREADY_HAVE_ACCOUNT_TEXT)}
              </span>
              <HyperLink
                handleClick={switchToSignIn}
                hyperText={t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA)}
                disabled={false}
              />
            </div>
          </div>
        </div>

        <div className={styles.signupContainer}>
          <h4 className={styles.title}>
            {t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_FORM_WEB_HEADING)}
          </h4>
          <div className={styles.signupForm}>
            <div className={styles.signupFormCol}>
              <Input
                placeHolderText={t(AppConstant.PROFILE.ENTER_FIRST_NAME)}
                formControlVariant={'standard'}
                labelText={t(AppConstant.AUTHENTICATION_PAGE.FIRST_NAME_TEXT)}
                inputId={'firstName'}
                inputVariant={'outlined'}
                isRequired={true}
                value={firstName}
                isError={inputTextValidation(firstName)}
                errorMessage={t(AppConstant.ERROR.ENTER_VALID_FIRST_NAME)}
                handleChange={(e: eventType) =>
                  setFirstName(capitalizeFirstLetter(e.target.value))
                }
              />
            </div>
            <div className={styles.signupFormCol}>
              <Input
                placeHolderText={t(AppConstant.PROFILE.ENTER_LAST_NAME)}
                formControlVariant={'standard'}
                labelText={t(AppConstant.AUTHENTICATION_PAGE.LAST_NAME_TEXT)}
                inputId={'lastName'}
                inputVariant={'outlined'}
                isRequired={true}
                value={lastName}
                isError={inputTextValidation(lastName)}
                errorMessage={t(AppConstant.ERROR.ENTER_VALID_LAST_NAME)}
                handleChange={(e: eventType) =>
                  setLastName(capitalizeFirstLetter(e.target.value))
                }
              />
            </div>
            <div className={styles.signupFormCol}>
              <MobileInput
                placeHolderText={t(AppConstant.PROFILE.ENTER_MOBILE_NUMBER)}
                defaultCountryCode={countryCode}
                countryValue={countryCode}
                formControlVariant='standard'
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.MOBILE_NUMBER_TEXT
                )}
                inputId='mobileNumbar'
                inputVariant='outlined'
                isRequired={true}
                dropdownVariant='outlined'
                dropdownOptions={countryList}
                isError={
                  mobileStartZeroValidation(mobileNumber) ||
                  mobileValidation(mobileNumber, mobileLength)
                }
                countryCodeError={countryCodeValidation(countryCode)}
                errorMessage={
                  mobileStartZeroValidation(mobileNumber)
                    ? t(AppConstant.ERROR.MOBILE_VALIDATION_FOR_START_ZERO)
                    : t(AppConstant.ERROR.ENTER_VALID_MOBILE)
                }
                handleChange={(e: eventType) => setMobileNumber(e.target.value)}
                handleOnBlur={fetchRegistrationCode}
                handleCountryCodeChange={(e: any) => {
                  mobileStartZeroValidation(mobileNumber);
                  mobileValidation(mobileNumber, mobileLength);
                  handleCountryCodeChange(e.target.value);
                }}
                maxMobileLength={mobileLength}
                countryFlag={countryFlag}
              />
            </div>
            <div className={styles.signupFormCol}>
              <Input
                placeHolderText={t(AppConstant.PROFILE.ENTER_YOUR_EMAIL)}
                formControlVariant={'standard'}
                labelText={t(AppConstant.AUTHENTICATION_PAGE.EMAIL_TEXT)}
                inputId={'email'}
                inputVariant={'outlined'}
                isRequired={true}
                isError={emailValidation(emailId)}
                errorMessage={t(AppConstant.ERROR.ENTER_VALID_EMAIL)}
                handleChange={(e: eventType) => setEmailId(e.target.value)}
                handleOnBlur={fetchRegistrationCode}
              />
            </div>
            <div className={styles.signupFormCol}>
              <Dropdown
                defaultValue=''
                value={selectedLanguage}
                dropdownId='grouped-select'
                dropdownVariant='outlined'
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.PREFERRED_LANGUAGE
                )}
                dropdownOptions={preferredLanguagesList}
                isError={dropDownValidation(selectedLanguage)}
                errorMessage={t(AppConstant.ERROR.ENTER_VALID_PREFFERED_LANG)}
                handleDropDownChange={(e: SelectChangeEvent) =>
                  setSelectedLanguage(e.target.value)
                }
                placeholder=''
                isLanguage={true}
              />
            </div>

            {!RESTRICT_REGISTRATION_REFERRAL_CODE_FIELD && (
              <div className={styles.signupFormCol12}>
                <RadioGroupComponent
                  formControlVariant={'standard'}
                  labelText={t(AppConstant.AUTHENTICATION_PAGE.I_HAVE_TEXT)}
                  inputId={'verificationCode'}
                  isRequired={true}
                  radioGroupObj={verificationCodeObj}
                  selectedValue={selectedCodeType}
                  handleRadioChange={handleCodeTypeChange}
                  isError={radioGroupValidation(selectedCodeType)}
                  errorMessage={t(AppConstant.ERROR.ENTER_VALID_OPTION)}
                />
                {codeTextField && (
                  <div className={styles.registrationDiv}>
                    <Input
                      placeHolderText={t(
                        AppConstant.PROFILE.ENTER_VALID_REGISTRATION_CODE
                      )}
                      formControlVariant={'standard'}
                      value={codeText}
                      labelText={''}
                      inputId={'codeText'}
                      inputVariant={'outlined'}
                      isRequired={false}
                      isError={
                        isCodeInvalid || registrationCodeValidation(codeText)
                      }
                      disabled={isRegistrationCodeDisabled}
                      errorMessage={t(AppConstant.ERROR.ENTER_VALID_CODE)}
                      handleChange={(e: eventType) =>
                        handleCodeChange(e.target.value)
                      }
                    />
                    {isRegistrationCodeLoading && (
                      <span className={styles.registrationLoader}>
                        <CircularProgress
                          size={20}
                          thickness={4}
                          sx={{
                            color: 'var(--secondaryOrange)',
                          }}
                        />
                      </span>
                    )}
                  </div>
                  // <input type="text" className={styles.referralCodeInput} />
                )}
              </div>
            )}
            <div className={styles.signupFormCol12}>
              <RadioGroupComponent
                formControlVariant={'standard'}
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.GET_VERIFICATION_CODE_ON_TEXT
                )}
                inputId={'verificationCode'}
                isRequired={true}
                radioGroupObj={contactModes}
                selectedValue={selectedVerificationMedium}
                handleRadioChange={handleVerificationMediumChange}
                isError={radioGroupValidation(selectedModeLabel?.toLowerCase())}
                errorMessage={t(AppConstant.ERROR.ENTER_VALID_MODE)}
              />
            </div>
            <div className={styles.signupFormCol12}>
              <div className={styles.termCheck}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event: any) =>
                        setTermsCheck(event.target?.checked)
                      }
                    />
                  }
                  label=''
                />
                <div className={styles.termText}>
                  {t(
                    AppConstant.AUTHENTICATION_PAGE
                      .TERMS_AND_CONDITION_CONCENT_PART1
                  )}
                  <HyperLink
                    handleClick={handleTermAction}
                    hyperText={t(
                      AppConstant.AUTHENTICATION_PAGE
                        .TERMS_AND_CONDITION_CONCENT_PART2
                    )}
                    disabled={false}
                  />
                  {t(
                    AppConstant.AUTHENTICATION_PAGE
                      .TERMS_AND_CONDITION_CONCENT_PART3
                  )}
                </div>
                {termsCheck === false && (
                  <p className={styles.checkError}>
                    {t(
                      AppConstant.AUTHENTICATION_PAGE
                        .TERMS_CONDITIONS_VALIDATION_TEXT
                    )}
                  </p>
                )}
              </div>
            </div>

            <div className={styles.signupCta}>
              <ButtonComponent
                variant='outlined'
                buttonText={t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_CTA)}
                onPress={handleNext}
                disabled={!nextCtaEnable}
              />
            </div>

            {/* <div className={styles.signupFormCol12}>
              <div className={styles.divider}>
                <HorizontalDivider />
              </div>
            </div>  */}
            <div className={styles.toggleLogin}>
              <span>{t(AppConstant.COMMON.FACING_ISSUE_TEXT)}</span>
              <HyperLink
                handleClick={handleHelpSupport}
                hyperText={t(AppConstant.HEADER.HELP_SUPPORT_MENU)}
                disabled={false}
              />
            </div>
          </div>
        </div>
        {termsModalOpen && (
          <Modal
            modalOpen={termsModalOpen}
            handleModalClose={handleTermsModalClose}
            className='termsModal'
          >
            <div className={styles.termsModalHead}>
              <h4 className={styles.title}>Terms and Conditions</h4>
              <span className={styles.closeCta} onClick={handleTermsModalClose}>
                &times;
              </span>
            </div>
            <div className={styles.termsContent}>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Overview</h4>
                <p>
                  The ‘terms of use’ govern the use of Shalina MedSpace owned
                  and operated by Shalina Healthcare. By using or accessing the
                  Shalina MedSpace app intended for healthcare professionals,
                  you agree to be bound and abide by the following Terms of Use.
                </p>
                <p>
                  If Shalina MedSpace expands through the acquisition of another
                  company and/or its products, the acquired company may operate
                  its properties according to its own terms of use until we
                  integrate its practices with ours. Upon integration, a link to
                  these Terms will be displayed on the acquired company's
                  properties. By using the Services, whether or not you are a
                  registered member of Shalina MedSpace, you agree to these
                  Terms. They serve as the governing rules for your use of the
                  Services, establishing a binding legal agreement that we can
                  enforce in the event of a violation.
                </p>
                <p>
                  The Services provided are uniquely tied to your registration
                  information and interests provided to the Shalina MedSpace
                  app. We algorithmically use this information to locate and
                  identify news, journal and magazine article headlines and
                  other original content accessed by you to tailor and
                  personalise your reading and usage recommendations.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Changes to Terms of Use</h4>
                <p>
                  To utilize our suite of services, you are required to register
                  and create an account on the Shalina MedSpace app. This
                  involves providing us with details of your name, email
                  address, profession, specialty, medical license number, and
                  other details outlined in the registration form. Additionally,
                  you must select a username and password linked to your
                  account. By doing so, you affirm that the information you
                  provide is truthful, accurate, current, and complete. It is
                  your responsibility to regularly update your profile and
                  information to ensure ongoing accuracy. Shalina MedSpace
                  reserves the right to verify the accuracy of the information
                  you share.
                </p>
                <p>
                  You bear sole responsibility for safeguarding the
                  confidentiality and security of your account username and
                  password, and you may not authorize another individual to use
                  your credentials to access services provided by Shalina
                  MedSpace. You are accountable for all activities conducted on
                  your account. In the event of a suspected compromise of your
                  account, you are expected to promptly modify your username and
                  password using the account settings feature or inform us of a
                  breach via the support channel. Shalina MedSpace assumes no
                  liability for any unauthorized access or use of your account
                  information.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Use of Services</h4>
                <p>
                  Services provided by the Shalina MedSpace app are intended for
                  physicians and other healthcare professionals only. By using
                  the Services, you represent and warrant that you have the
                  necessary medical license, right, authority, and capacity to
                  agree to and abide by these Terms of Use.
                </p>
                <p>
                  The information made available through the application are
                  provided for educational purposes only. The services and the
                  platform in no way serve for diagnostic purposes and/or
                  recommend particular products or therapies that may serve as a
                  substitute for the advice of a qualified healthcare
                  professional.
                </p>
                <p>
                  You are solely responsible for evaluating the information
                  obtained from the Services and for your use or misuse of such
                  information in connection with your treatment decisions or
                  otherwise. You agree that you shall be solely responsible for
                  your compliance with all laws and standards of professional
                  practice applicable to you and the practice of medicine or
                  other relevant health profession.
                </p>
                <p>
                  Subject to your compliance with these Terms, you are granted a
                  limited, non-exclusive, non-transferable and non-sublicensable
                  license to use the Services and to view the information and
                  materials made available solely for professional use. You
                  acknowledge and agree that the Shalina MedSpace app and
                  software used in connection contain proprietary and
                  confidential information that is protected by applicable
                  intellectual property and other laws. You shall not use, copy,
                  adapt, modify, prepare derivative works based upon,
                  distribute, license, sell, transfer, publicly display,
                  publicly perform, transmit, stream, broadcast or otherwise
                  exploit the Services or Content provided on the Shalina
                  MedSpace app.
                </p>
                <p>
                  Should the Shalina MedSpace app contain links to third-party
                  resources or websites which are not part of our network, you
                  agree that we are not responsible or liable for these websites
                  and resources including their availability or the content and
                  information that they provide. Inclusion in the of third-party
                  resources, including links to third party websites, does not
                  imply our endorsement of these resources.
                </p>
                <p>
                  You agree that you will not engage in any of the following
                  activities in connection with your use of the Services:
                </p>
                <ol>
                  <li>
                    Falsify headers or manipulate identifiers to conceal the
                    origin of any content available through our Services.
                  </li>
                  <li>
                    Remove any copyright, trademark, or other proprietary rights
                    notices within the app.
                  </li>
                  <li>
                    Use our brands, logos, trademarks, or other proprietary
                    marks.
                  </li>
                  <li>
                    Attempt to bypass any protective measures associated with
                    the access of our Services.
                  </li>
                  <li>
                    Misrepresent your affiliation with Shalina MedSpace and our
                    community.
                  </li>
                  <li>
                    Harvest or collect personal information about other
                    users/partners.
                  </li>
                  <li>
                    Interfere with or disrupt any of the Services or the
                    associated computer or technical delivery systems.
                  </li>
                  <li>
                    Interfere with, or attempt to interfere with, the access of
                    any user, host, or network.
                  </li>
                  <li>
                    Violate another user's privacy, including disclosing another
                    user's personally identifiable information.
                  </li>
                  <li>
                    Use any services or property, and content in a manner not
                    permitted by these Terms.
                  </li>
                </ol>
                <p>We may do any or all of the following without notice:</p>
                <ol>
                  <li>
                    Investigate your use of the app and its Services as we deem
                    appropriate to comply with any applicable law, regulation,
                    government request or legal process.
                  </li>
                  <li>
                    Remove User Content which we believe does not comply with
                    our Terms of Use.
                  </li>
                  <li>
                    Terminate your access to Shalina MedSpace upon breach or
                    violation of these Terms of Use.
                  </li>
                  <li>Edit content posted by users on the app.</li>
                </ol>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Copyright Notice</h4>
                <p>
                  This platform and all of its content are protected by
                  copyright. The contents should not be copied under any
                  circumstances without the explicit prior consent of the
                  exclusive platform partner of the company and the company
                  itself. Unless expressly authorized here, users are not
                  allowed to display, download, distribute, reproduce,
                  republish, or transmit any information, text, images, or
                  documents contained in the platform without the express prior
                  written consent of the company and its exclusive platform
                  partner.
                </p>
                <p>
                  Unauthorized use of platform content, without proper citation,
                  is strictly prohibited and constitutes infringement on
                  intellectual property rights, potentially resulting in civil
                  and criminal penalties, including monetary damages for
                  copyright infringement. Any content, trademark(s), or other
                  material on the platform not owned by the company or its
                  exclusive platform partner remains the copyright of its
                  respective owner(s). The company and its exclusive platform
                  partner disclaim ownership or responsibility for such items,
                  and users should obtain legal consent from the owner for any
                  use of such materials.
                </p>
                <p>
                  Users are explicitly prohibited from using platform content or
                  incorporating portions of it into another website without
                  obtaining prior written permission from the company's
                  exclusive platform partner. Additionally, users are not
                  allowed to link to the platform if engaged in the publication
                  or promotion of illegal, obscene, or offensive content, or if
                  the link negatively impacts the reputation of the company
                  and/or its exclusive platform partner.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Use of Information</h4>
                <p>
                  The organization does not guarantee or assert that the
                  utilization of the Content will not violate the rights of
                  third parties. The use of this Platform does not grant any
                  license or entitlement to the Content or any copyright
                  belonging to the exclusive Platform partner of the Company in
                  said Content.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Medical Information</h4>
                <p>
                  Information about on this app, including, but not limited to
                  scientific literature, research materials, and related
                  presentations, is supplied by the company’s exclusive Platform
                  Partner as part of the Services. This information is intended
                  for reference use, solely by healthcare professionals and
                  should not be accessed or utilized by any other individual or
                  entity. The app is not designed for product promotion but is
                  dedicated to the advancement of knowledge.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Video Disclaimer</h4>
                <p>
                  The content will be disseminated via electronic media,
                  encompassing, but not limited to, videos, webinars, interviews
                  with healthcare professionals, CME programs, and articles.
                  This content reflects the views of the individuals expressing
                  them and is not directly or indirectly controlled by Shalina
                  MedSpace or its exclusive Platform Partner. The inclusion of
                  such content through any electronic means does not signify
                  endorsement by the Company or its exclusive Platform Partner.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>
                  Information captured through the services
                </h4>
                <p>
                  Some of our Services may capture and store user content that
                  may include, but not be limited to data, text, images, sound,
                  and interactions. By using the Services and submitting the
                  information, you automatically grant us license to copy,
                  publicly host, modify, create derivative works and distribute
                  such content in any format, on any media or platform, both
                  within and outside of the Shalina Network.
                </p>
                <p>
                  Shalina MedSpace may store or use the User Content limited to,
                  reproduction, disclosure, transmission, publication, broadcast
                  or further posting only for the following intended purposes:
                </p>
                <ul>
                  <li>
                    Responding to User queries regarding the app, its Services,
                    or Contents
                  </li>
                  <li>Facilitating efficient communication with Users</li>
                  <li>
                    Providing content recommendations specifically, but not
                    limited to video recordings of various CME programmes,
                    medical and scientific information, scientific research
                    materials- articles, presentations, books, magazines and
                    journals
                  </li>
                </ul>
                <p>
                  You are solely responsible for ensuring that the User Content
                  submitted and captured on the app complies with the applicable
                  laws, regulations, privacy, and clinical and ethical
                  practices. You comply that you will not use the Services to
                  make available or share User Content that:
                </p>
                <ul>
                  <li>Is guarded by any contractual agreement or law</li>
                  <li>
                    Infringes or violates a third party’s privacy, copyright,
                    trademark, patent or intellectual property
                  </li>
                  <li>
                    Violates any applicable data protection acts, laws or
                    regulations for the African Union
                  </li>
                  <li>
                    Is harmful, obscene, defamatory, threatening, harassing,
                    abusive, slanderous, offensive, or embarrassing to any other
                    person or entity
                  </li>
                  <li>
                    Promotes discrimination, bigotry, racism, hatred, harassment
                    or harm against any individual or group
                  </li>
                  <li>
                    Promotes illegal activity, or is fraudulent, false,
                    misleading or deceptive
                  </li>
                  <li>
                    Promotes a business through direct or indirect advertisement
                  </li>
                  <li>
                    Contains viruses or other harmful computer code designed to
                    interrupt, destroy or limit the use of the application
                  </li>
                </ul>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Privacy Policy</h4>
                <p>
                  The Shalina MedSpace Privacy Policy, provides information
                  about our collection, use and disclosure of information about
                  users of the Services. By accessing and using the Services,
                  you agree to the terms of the Privacy Policy.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>
                  Laws that Govern this Agreement
                </h4>
                <p>
                  By accessing the Shalina MedSpace app and Services, you agree
                  that the statutes and laws of the African Union apply to all
                  matters relating to use.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Termination and Modification</h4>
                <p>
                  You agree that we Shalina MedSpace, under certain
                  circumstances and without prior notice, can discontinue,
                  temporarily or permanently, your access to the app’s Services,
                  or eliminate your account and remove any User Content you have
                  made available through the Services, with or without notice,
                  for:
                </p>
                <ul>
                  <li>
                    Attempted breaches or violations of the Terms or other
                    agreements or guidelines
                  </li>
                  <li>
                    Requests by law enforcement or other government agencies
                  </li>
                  <li>Requests posted by you</li>
                  <li>
                    Discontinuation or material modification to the Services (or
                    any part thereof)
                  </li>
                  <li>Technical or security issues or problems</li>
                  <li>Your engagement in fraudulent or illegal activities</li>
                </ul>
                <p>
                  You agree that all terminations for cause shall be made at our
                  sole discretion, and we shall not be liable to you or any
                  third party for any termination of your account or access to
                  the Services.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Liability</h4>
                <p>
                  In no event will Shalina MedSpace or associated resources
                  involved in creating, developing or delivering the app and the
                  Services be liable for any damages (incidental and
                  consequential), personal injury/wrongful death, or inability
                  to access or use the app and its Services.
                </p>
                <p>
                  The information, software, products, and services provided in
                  or accessible through the Platform may contain inaccuracies or
                  typographical errors. Shalina MedSpace and/or its exclusive
                  Platform Partner(s) disclaims any liability or responsibility
                  for such errors and denies all warranties and conditions,
                  including implied warranties and conditions of
                  merchantability, fitness, title, completeness, and
                  non-infringement related to the mentioned information.
                  Although the app aims to keep the information current, the
                  owners and contributors to this Platform make no assurances
                  about the accuracy, completeness, or adequacy of the
                  information.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Warranty Disclaimer</h4>
                <p>
                  YOU ACKNOWLEDGE AND AGREE THAT YOUR UTILIZATION OF THE
                  PLATFORM AND ITS CONTENT IS ENTIRELY AT YOUR OWN RISK. TO THE
                  MAXIMUM EXTENT PERMITTED BY LAW, THE COMPANY AND/OR ITS
                  EXCLUSIVE PLATFORM PARTNER, ALONG WITH THEIR RESPECTIVE
                  OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS, DISCLAIM ALL
                  WARRANTIES, WHETHER EXPRESS OR IMPLIED, REGARDING THE SERVICES
                  AND YOUR USAGE OF THEM.
                </p>
                <p>
                  TO THE FULLEST EXTENT PERMITTED BY LAW, THE COMPANY AND/OR ITS
                  EXCLUSIVE PLATFORM PARTNER DISAVOW ALL WARRANTIES, CONDITIONS,
                  TERMS, OR REPRESENTATIONS RELATED TO THE ACCURACY OR
                  COMPLETENESS OF THE CONTENT ON THIS PLATFORM OR THE CONTENT OF
                  ANY PLATFORMS LINKED TO IT. THEY ASSUME NO LIABILITY OR
                  RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES
                  IN THE CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE
                  RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (III)
                  UNAUTHORIZED ACCESS TO OR USE OF SECURE SERVERS AND/OR ANY
                  PERSONAL OR FINANCIAL INFORMATION STORED THEREIN, (IV)
                  INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                  PLATFORM, (V) BUGS, VIRUSES, TROJAN HORSES, OR SIMILAR ISSUES
                  TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY,
                  AND/OR (VI) ERRORS OR OMISSIONS IN ANY CONTENT, OR FOR ANY
                  LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
                  ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE
                  AVAILABLE VIA THE PLATFORM.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Indemnity</h4>
                <p>
                  Subject to applicable law, the user agrees to defend,
                  indemnify, and hold the Company, its parent corporation,
                  and/or its exclusive platform partner, as well as their
                  respective affiliates, officers, directors, employees, and
                  agents, harmless from any and all claims, damages,
                  obligations, losses, liabilities, costs, or debt, including
                  attorney's fees, arising from: (i) your use of and access to
                  the Platform and/or the Content; (ii) your violation of any
                  term outlined in these Terms & Conditions; (iii) your
                  infringement upon any third-party rights, including but not
                  limited to any copyright, property, or privacy right; or (iv)
                  any claim asserting that the Content accessed and used by you
                  and/or your User Information caused damage to a third party.
                  It is important to note that this indemnity clause will remain
                  in effect even if you cease to be a user of the Platform.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Accept Terms of Service</h4>
                <p>
                  By confirming, you assert that you are either over 18 years of
                  age and possess the full capacity to enter into the Terms &
                  Conditions. You affirm your ability to undertake the
                  responsibilities, affirmations, representations, and
                  warranties outlined in these Terms & Conditions, and commit to
                  abide by and adhere to this Terms of Service agreement. You
                  recognize that we have provided you with a reasonable
                  opportunity to peruse these Terms & Conditions, and you
                  willingly consent to them.
                </p>
              </div>
              <div className={styles.contentSec}>
                <h4 className={styles.subHead}>Amendment of Terms of Use</h4>
                <p>
                  Shalina MedSpace reserves the right to amend, modify, or
                  update these terms and conditions at any time, without prior
                  notice, to ensure compliance with emerging regulations,
                  including but not limited to the Data Protection Act and other
                  applicable laws. Users are encouraged to review these terms
                  periodically for any changes. By continuing to use our
                  services after the posting of any amendments, you agree to be
                  bound by the revised terms and conditions. Shalina MedSpace
                  shall not be liable for any failure to notify users of such
                  amendments. It is the responsibility of users to stay informed
                  about the latest terms and conditions.
                </p>
              </div>
            </div>
          </Modal>
        )}
        {authenticationLoading && <CircularLoader size={50} thickness={4} />}
        <Toast
          showToast={showToast}
          isSuccess={isSuccess}
          message={toastMessage}
          subMessage={toastSubMessage}
          handleToastClose={handleToastClose}
        />

        <Modal modalOpen={modalOpen} handleModalClose={handleModalClose}>
          <div className={styles.modalWrap}>
            <h2 className={styles.title}>{t(AppConstant.ERROR.ERROR_TEXT)}</h2>
            <button onClick={handleModalClose} className={styles.closeCta}>
              &times;
            </button>
            <span>{toastMessage}</span>
            {modalCtaAction && modalCtaAction !== '' && <div className={styles.modalCtaWrap}>
            <button onClick={onModalCtaPress}>
              {modalCtaAction}
            </button>
          </div>}
          </div>
        </Modal>
      </div>
    </>
  );
};
