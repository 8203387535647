import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppConstant,
  getLoggedInUserDetail,
  getOTPDetails,
  sendOtpApi,
  SEND_OTP_ACTION_TYPE,
  signInApi,
  signUpApi,
  translate,
  useAppDispatch,
  useAppSelector,
  ImageConstant,
  changeLanguage,
  updateLanguageForWeb,
  RESEND_TIMER,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  PlatformNames,
  getAnalyticsUserDetails,
  getLoggedInUserInfo,
  isEmptyCheck,
} from '@shalina-app/shared';

import styles from './verification.module.scss';
import {
  Input,
  ButtonComponent,
  HyperLink,
  CircularLoader,
  Toast,
} from '../../../../components';
import { URLConstant } from '../../../../routes';
import {
  eventType,
  otpValidation,
  useNavigatePageUtils,
  storeAccessToken,
  storeData,
  STORAGE_CONSTANTS,
  storeProfilePic,
  retrieveData,
} from '../../../../utils';
import { pageNames } from '../../../../app.constants';
import { appInsights } from '../../../../applicationInsight';

export const VerificationPage: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const t = translate();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;

  const location = useLocation();
  const statePathName = location?.state?.pathName;
  const rssContentData = location?.state?.rssContentData;
  const isReferral = location?.state?.isReferral;

  const { sourcePage } = location?.state;
  const {
    firstName,
    lastName,
    language,
    requestForReferralCode,
    registrationCode,
    isTermsConditionAccepted,
    otpSent,
    email,
    mobile,
    country,
    mode,
  } = location?.state?.formData;

  const otpDetails = useAppSelector(getOTPDetails);
  const authenticationLoading = useAppSelector(
    (state) => state?.authentication?.isLoading
  );
  const loggedInUser = useAppSelector(getLoggedInUserDetail);

  const [timer, setTimer] = useState(RESEND_TIMER);
  const [resendEnable, setResendEnable] = useState(false);
  const [otp, setOtp] = useState('');
  const [nextCtaEnable, setNextCtaEnable] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [resendClick, setResendClick] = useState(false);
  const [sendNotificationState, setSendNotificationState] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const storeLanguage = async (lang: any) => {
    await dispatch(updateLanguageForWeb(lang));
  };

  const handleNext = async () => {
    const urlToNavigate =
      sourcePage === pageNames.SignUp ? urls.profileSetupPage : urls.homePage;
    if (sourcePage === pageNames?.SignUp) {
      const payload = {
        countryCode: country || otpDetails?.countryCode,
        mobileNumber: mobile || otpDetails?.mobileNumber,
        emailId: email || otpDetails?.emailId,
        mode: mode || otpDetails?.mode,
        firstName: firstName,
        lastName: lastName,
        language: language,
        registrationCode: registrationCode,
        requestForReferralCode: requestForReferralCode,
        isTermsConditionAccepted: isTermsConditionAccepted,
        otp: otp,
      };
      const response = await dispatch(signUpApi(payload)).unwrap();

      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setShowToast(true);
        setIsSuccess(true);
        setResendClick(false);
        setToastMessage(t(response?.message));

        changeLanguage(response?.data?.language?.field_unique_code);
        storeLanguage(response?.data?.language?.field_unique_code);
        storeData(
          STORAGE_CONSTANTS.SELECTED_LANGUAGE,
          response?.data?.language
        );

        setNextCtaEnable(false);
        const navigateDetails = {
          isDynamicUrl: false,
          stateParams: {
            rssContentData: rssContentData,
            pathName: statePathName,
            isReferral: isReferral
          },
        };

         // User session KPI Changes
         const sessionLog = retrieveData(
          STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME
        );
        if(isEmptyCheck(sessionLog)) {
          const date = new Date();
          const currentDateTime = date?.toISOString();
          storeData(STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME, currentDateTime);
        }

        navigatePageUtils(urlToNavigate, navigateDetails);
      } else {
        setNextCtaEnable(false);
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(response?.message));
        // setToastMessage("Sign Up Failed!");
        // setToastSubMessage("Please check your credentials and try again.");
      }
    } else if (sourcePage === pageNames?.SignIn) {
      const payload = {
        countryCode: country || otpDetails?.countryCode,
        mobileNumber: mobile || otpDetails?.mobileNumber,
        emailId: email || otpDetails?.emailId,
        mode: mode || otpDetails?.mode,
        otp: otp,
      };
      const response = await dispatch(signInApi(payload)).unwrap();

      if (
        response &&
        response?.status === 'SUCCESS' &&
        response?.statusCode === 200
      ) {
        setShowToast(true);
        setIsSuccess(true);
        setToastMessage(t(response?.message));
        // setToastMessage("Sign In Successful!");
        // setToastSubMessage("Welcome aboard! Your signin is successful.");
        changeLanguage(response?.data?.language?.field_unique_code);
        storeLanguage(response?.data?.language?.field_unique_code);
        storeData(
          STORAGE_CONSTANTS.SELECTED_LANGUAGE,
          response?.data?.language
        );
        const userInfo = getAnalyticsUserDetails(response?.data);
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_VISIT,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
          }
        );
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_LOGIN_WEB,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
          }
        );

        setNextCtaEnable(false);
        const navigateDetails = {
          isDynamicUrl: false,
          stateParams: {
            sendNotification: true,
            rssContentData: rssContentData,
          },
        };

        // User session KPI Changes
        const sessionLog = retrieveData(
          STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME
        );
        if(isEmptyCheck(sessionLog)) {
          const date = new Date();
          const currentDateTime = date?.toISOString();
          storeData(STORAGE_CONSTANTS.USER_SESSION_FIRST_TIME, currentDateTime);
        }
        if (statePathName) {
          navigatePageUtils(statePathName, navigateDetails);
        } else {
          navigatePageUtils(urlToNavigate, navigateDetails);
        }
      } else {
        setNextCtaEnable(false);
        setShowToast(true);
        setIsSuccess(false);
        setToastMessage(t(response?.message));
        // setToastMessage("Sign In Failed!");
        // setToastSubMessage("Please check your credentials and try again.");
      }
    }
  };

  // useEffect(() => {
  //   if (sendNotificationState) {
  //     sendNotificationCall();
  //     setSendNotificationState(false);
  //   }
  // }, [sendNotificationState]);

  // const sendNotificationCall = async () => {
  //   const action = gamificationData?.find(
  //     (item: any) =>
  //       item?.tid === GAMIFICATION_ACTION_ID.CONTINUE_WHERE_YOU_LEFT
  //   )?.title;

  //   const req = {
  //     action: action,
  //   };
  //   await dispatch(sendNotification(req));
  //   const navigateDetails = {
  //     isDynamicUrl: false,
  //   };

  //   navigatePageUtils(urls.homePage, navigateDetails);
  // };

  useEffect(() => {
    let interval: any;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setResendEnable(true);
      setResendClick(false);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const onResendOTP = async () => {
    // Reset the timer and disable the Resend button
    setOtp('');
    setIsError(false);
    setErrorMessage('');
    setResendEnable(false);
    setResendClick(true);
    // Add logic here to trigger OTP resend
    // For example, you can make an API call to request a new OTP
    const req = {
      payload: {
        countryCode: country || otpDetails?.countryCode,
        mobileNumber: mobile || otpDetails?.mobileNumber,
        emailId: email || otpDetails?.emailId,
        mode: mode || otpDetails?.mode,
      },
      params:
        sourcePage === pageNames.SignUp
          ? SEND_OTP_ACTION_TYPE.SIGN_UP_ACTION
          : SEND_OTP_ACTION_TYPE.SIGN_IN_ACTION,
    };
    const response: any = await dispatch(sendOtpApi(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
      // setToastSubMessage("OTP was sent successfully");
      setNextCtaEnable(false);
    } else {
      setNextCtaEnable(false);
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response?.message));
      // setToastSubMessage("Please check your credentials and try again.");
    }
    setTimer(RESEND_TIMER);
  };

  useEffect(() => {
    if (otp) {
      if (!otpValidation(otp)) {
        setIsError(false);
        setErrorMessage('');
        setNextCtaEnable(true);
      } else {
        setIsError(true);
        setErrorMessage(t(AppConstant.COMMON.ERROR_MESSAGE_VALID_OTP));
        setNextCtaEnable(false);
      }
    }
  }, [otp]);

  useEffect(() => {
    if (loggedInUser?.token) {
      storeAccessToken(loggedInUser?.token);
      storeData(STORAGE_CONSTANTS.IS_LOGGED_IN, true);
      storeData(STORAGE_CONSTANTS.USER, loggedInUser);
      storeProfilePic(loggedInUser?.userPicture);
    } else {
      storeData(STORAGE_CONSTANTS.IS_LOGGED_IN, false);
    }
  }, [loggedInUser]);

  const handleOtp = (value: string) => {
    setOtp(value);
    setIsError(false);
    setErrorMessage('');
  };

  const handleToastClose = () => {
    setShowToast(false);
    // const urlToNavigate =
    //   sourcePage === pageNames.SignUp ? urls.profileSetupPage : urls.homePage;
    // if (isSuccess && !resendClick && sourcePage === pageNames.SignUp) {
    //   const navigateDetails = {
    //     isDynamicUrl: false,
    //   };

    //   navigatePageUtils(urlToNavigate, navigateDetails);
    // } else {
    //   setShowToast(false);
    // }
  };
  const onLogoClick = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.homePage, navigateDetails);
  };

  const validateOnBlur = () => {
    if (otp?.length < 6) {
      setIsError(true);
      setErrorMessage(t(AppConstant.ERROR.OTP_REQUIRED));
    } else {
      setIsError(false);
      setErrorMessage('');
    }
  };
  const handleHelpSupport = () => {
    const navigateDetails = {
      isDynamicUrl: false,
    };
    navigatePageUtils(urls.helpSupport, navigateDetails);
  };

  const { AuthenticationBgIcon } = ImageConstant.SVG_ICONS;

  return (
    <div className={styles.signUpWrapper}>
      <div className={styles.authBg}>
        <AuthenticationBgIcon />
      </div>
      <div className='cust-container'>
        <div className={styles.signUplogo} onClick={onLogoClick}>
          <img
            src={ImageConstant.SOURCE.ShalinaMedspaceLogo}
            alt='logo'
            className={styles.logo}
          />
        </div>
      </div>
      <div className={styles.verificationContainer}>
        <div className={styles.verificationWrapper}>
          <p className={styles.title}>
            {t(AppConstant.AUTHENTICATION_PAGE.VERIFICATION_TEXT)}
          </p>
          <div className={styles.infoText}>
            {t(AppConstant.AUTHENTICATION_PAGE.VERIFICATION_CODE_SENT_TO_TEXT)}
            <span>{otpSent}</span>
          </div>
          <div className={styles.verificationInput}>
            <Input
              placeHolderText={t(AppConstant.PROFILE.ENTER_VERIFICATION_CODE)}
              formControlVariant={'standard'}
              inputId={'verificationCode'}
              inputVariant={'outlined'}
              isError={isError}
              handleOnBlur={validateOnBlur}
              errorMessage={errorMessage}
              value={otp}
              handleChange={(e: eventType) => handleOtp(e.target.value)}
              maxLength={6}
            />
          </div>
          <div className={styles.timeLink}>
            {!resendEnable ? (
              <div>
                {t(AppConstant.AUTHENTICATION_PAGE.TIME_REMAINING_TEXT)}{' '}
                <span>{timer}</span> {t(AppConstant.COMMON.SECONDS_TEXT)}
                {/* <span>{` ${Math.floor(timer / 60)}.${timer % 60}`}</span> */}
              </div>
            ) : (
              <span></span>
            )}
            <HyperLink
              handleClick={onResendOTP}
              hyperText={t(AppConstant.AUTHENTICATION_PAGE.RESEND_OTP_TEXT)}
              disabled={!resendEnable}
            />
          </div>
          <div className={styles.verificationCta}>
            <ButtonComponent
              variant='outlined'
              buttonText={
                sourcePage === pageNames.SignUp
                  ? t(AppConstant.AUTHENTICATION_PAGE.SIGN_UP_CTA)
                  : t(AppConstant.AUTHENTICATION_PAGE.SIGN_IN_CTA)
              }
              onPress={handleNext}
              disabled={!nextCtaEnable}
            />
          </div>
        </div>
        <div className={styles.toggleLogin}>
          <span>{t(AppConstant.COMMON.FACING_ISSUE_TEXT)}</span>
          <HyperLink
            handleClick={handleHelpSupport}
            hyperText={t(AppConstant.HEADER.HELP_SUPPORT_MENU)}
            disabled={false}
          />
        </div>
        {authenticationLoading && <CircularLoader size={50} thickness={4} />}
        <Toast
          showToast={showToast}
          isSuccess={isSuccess}
          message={toastMessage}
          subMessage={toastSubMessage}
          handleToastClose={handleToastClose}
        />
      </div>
      <div></div>
    </div>
  );
};
