import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppConstant,
  ImageConstant,
  getAreaOfInterest,
  getCountries,
  getQualification,
  getSpecialization,
  translate,
  updateUserDetails,
  useAppDispatch,
  useAppSelector,
  ANALYTICS_PAGE_NAMES,
  logEvent,
  CUSTOM_ANALYTICS_EVENT_NAMES,
  PlatformNames,
  getAnalyticsUserDetails
} from '@shalina-app/shared';
import { SelectChangeEvent } from '@mui/material';

import {
  Input,
  ButtonComponent,
  AutoComplete,
  ChipList,
  Dropdown,
  CircularLoader
} from '../../../../components';
import styles from './profileSetup.module.scss';
import {
  eventType,
  STORAGE_CONSTANTS,
  storeAccessToken,
  storeData,
  useNavigatePageUtils
} from '../../../../utils';
import { URLConstant } from '../../../../routes';
import { appInsights } from '../../../../applicationInsight';

export const ProfileSetupPage: React.FC<any> = () => {
  const t = translate();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const statePathName = location?.state?.pathName;
  const isReferral = location?.state?.isReferral;

  const qualificationList = useAppSelector(getQualification);
  const areaOfInterestList = useAppSelector(getAreaOfInterest);
  const specializationList = useAppSelector(getSpecialization);
  const countryOfPracticeList = useAppSelector(getCountries);

  const userLoading = useAppSelector((state) => state?.user?.isLoading);
  const authenticationLoading = useAppSelector(
    (state) => state?.authentication?.isLoading
  );
  const updatedUser = useAppSelector((state) => state?.user?.user);

  const [interestList, setInterestList] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [optionsSelected, setOptionsSelected] = useState<number[]>([]);
  const [specializedOptions, setSpecializedOptions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [countryOfPractice, setCountryOfPractice] = useState('');
  const [nextCtaEnable, setNextCtaEnable] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);

  useEffect(() => {
    setInterestList(areaOfInterestList);
  }, [areaOfInterestList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    logEvent(appInsights, 'pageView', ANALYTICS_PAGE_NAMES.PROFILE_SETUP);
    loadApi();
  }, []);

  const loadApi = async () => {
    // await dispatch(getSpecializationList());
    // await dispatch(getQualificationList());
    // await dispatch(getAreaOfInterests());
    // await dispatch(getCountryCodeList());
  };

  useEffect(() => {
    if (qualificationList && qualificationList?.length > 0) {
      storeData(STORAGE_CONSTANTS.QUALIFICATION_LIST, qualificationList);
    }
    if (specializationList && specializationList?.length > 0) {
      storeData(STORAGE_CONSTANTS.SPECIALIZATION_LIST, specializationList);
    }
    if (areaOfInterestList && areaOfInterestList?.length > 0) {
      storeData(STORAGE_CONSTANTS.AREA_OF_INTEREST_LIST, areaOfInterestList);
    }
    if (countryOfPracticeList && countryOfPracticeList?.length > 0) {
      storeData(
        STORAGE_CONSTANTS.COUNTRY_OF_PRACTICE_LIST,
        countryOfPracticeList
      );
    }
  }, [
    qualificationList,
    specializationList,
    areaOfInterestList,
    countryOfPracticeList
  ]);

  const startJourney = async () => {
    const selectedSpecialized = specializedOptions.map((item: any) => {
      return item.id;
    });
    const selectedQualification = qualificationOptions.map((item: any) => {
      return item.id;
    });

    const req = {
      qualification: selectedQualification || [],
      areaOfInterest: optionsSelected || [],
      specialization: selectedSpecialized || [],
      country: [countryOfPractice] || []
    };
    const response = await dispatch(updateUserDetails(req)).unwrap();

    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      if (response?.data?.token) {
        storeAccessToken(response?.data?.token);
        const userInfo: any = getAnalyticsUserDetails(response?.data)
        if (isReferral) {
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_REGISTRATION,
            {
              referralCode: response?.data?.registrationCode || '',
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              platform: PlatformNames.WEB,
            }
          );
        } else {
          logEvent(
            appInsights,
            'custom',
            CUSTOM_ANALYTICS_EVENT_NAMES.USER_REGISTRATION,
            {
              userEmail: userInfo?.emailId,
              userMobile: userInfo?.mobile,
              userName: userInfo?.name,
              userQualification: userInfo?.qualification,
              userInterest: userInfo?.areaOfInterest,
              userSpecialization: userInfo?.specialization,
              userCountryOfPractice: userInfo?.countryOfPractice,
              userPreferredLanguage: userInfo?.preferredLanguage,
              platform: PlatformNames.WEB,
            }
          );
        }
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_VISIT,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
          }
        );
        logEvent(
          appInsights,
          'custom',
          CUSTOM_ANALYTICS_EVENT_NAMES.USER_LOGIN_WEB,
          {
            userEmail: userInfo?.emailId,
            userMobile: userInfo?.mobile,
            userName: userInfo?.name,
            userQualification: userInfo?.qualification,
            userInterest: userInfo?.areaOfInterest,
            userSpecialization: userInfo?.specialization,
            userCountryOfPractice: userInfo?.countryOfPractice,
            userPreferredLanguage: userInfo?.preferredLanguage,
            platform: PlatformNames.WEB,
          }
        );
      }
    }
    await setCheckStatus(true);
  };

  useEffect(() => {
    const navigateDetails = {
      isDynamicUrl: false
    };

    if (checkStatus && updatedUser?.status === 'ERROR') {
      setCheckStatus(false);
    } else if (checkStatus && updatedUser?.status === 'SUCCESS') {
      if (updatedUser?.data) {
        storeData(STORAGE_CONSTANTS.USER, updatedUser?.data);
      }
      if (statePathName) {
        navigatePageUtils(statePathName, navigateDetails);
      } else {
        navigatePageUtils(urls.homePage, navigateDetails);
      }
    }
  }, [updatedUser, checkStatus]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleInterestSearch = (text: string) => {
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource and update FilteredDataSource
      const newData = areaOfInterestList.filter((item: any) => {
        // Applying filter for the inserted text in search bar
        const itemLabel = item?.label?.toUpperCase();
        const inputText = text.toUpperCase();

        return itemLabel.indexOf(inputText) > -1;
      });
      setInterestList(newData);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setInterestList(areaOfInterestList);
    }
  };

  const handleSkip = async () => {
    const navigateDetails = {
      isDynamicUrl: false
    };

    const selectedSpecialized = specializedOptions.map((item: any) => {
      return [item.id];
    });
    const selectedQualification = qualificationOptions.map((item: any) => {
      return [item.id];
    });

    const req = {
      qualification: selectedQualification,
      areaOfInterest: optionsSelected || [],
      specialization: selectedSpecialized,
      country: countryOfPractice
    };
    await dispatch(updateUserDetails(req));

    if (statePathName) {
      navigatePageUtils(statePathName, navigateDetails);
    } else {
      navigatePageUtils(urls.homePage, navigateDetails);
    }
  };

  const totalStep = 2;

  const handleSpecializedOptions = (options: any) => {
    setSpecializedOptions(options);
  };
  const handleQualificationOptions = (options: any) => {
    setQualificationOptions(options);
  };

  useEffect(() => {
    if (
      countryOfPractice &&
      countryOfPractice.length &&
      qualificationOptions &&
      qualificationOptions.length > 0 &&
      specializedOptions &&
      specializedOptions.length > 0
    ) {
      setNextCtaEnable(true);
    } else {
      setNextCtaEnable(false);
    }
  }, [countryOfPractice, qualificationOptions, specializedOptions]);
  const { AuthenticationBgIcon } = ImageConstant.SVG_ICONS;
  // const onLogoClick = () => {
  //   const navigateDetails = {
  //     isDynamicUrl: false,
  //   };
  //   navigatePageUtils(urls.homePage, navigateDetails);
  // };
  return (
    <div className={styles.signUpWrapper}>
      <div className={styles.authBg}>
        <AuthenticationBgIcon />
      </div>
      <div className='cust-container'>
        <div className={styles.signUplogo}>
          <img
            src={ImageConstant.SOURCE.ShalinaMedspaceLogo}
            alt='logo'
            className={styles.logo}
          />
        </div>
      </div>
      <div className={styles.profileSetupContainer}>
        <div className={styles.title}>
          {t(AppConstant.AUTHENTICATION_PAGE.PROFILE_SETUP_TEXT)} ({activeStep}/
          {totalStep})
          {/* {activeStep === 2 && (
            <span className={styles.skipCta} onClick={handleSkip}>
              {t(AppConstant.AUTHENTICATION_PAGE.SKIP_CTA)}
            </span>
          )} */}
        </div>
        <div className={styles.stepwrapper}>
          <div
            className={`${styles.step} ${
              (activeStep === 1 || activeStep === 2) && `${styles.active}`
            }`}
          ></div>
          <div
            className={`${styles.step} ${
              activeStep === 2 && `${styles.active}`
            }`}
          ></div>
        </div>

        {activeStep === 1 && (
          <div className={`${styles.profileFirstStep}`}>
            <div className={styles.profileFormCol}>
              <AutoComplete
                placeholder={t(AppConstant.PROFILE.SELECT_YOUR_SPECIALIZATION)}
                options={specializationList}
                id='specialty'
                labelText={t(AppConstant.AUTHENTICATION_PAGE.SPECIALTY)}
                isRequired={true}
                isError={false}
                errorMessage={t(AppConstant.ERROR.INCORRECT)}
                selectedOptions={specializedOptions}
                onSelectChange={handleSpecializedOptions}
                // handleChange={handleSpecializedOptions}
              />
            </div>
            <div className={styles.profileFormCol}>
              <AutoComplete
                placeholder={t(
                  AppConstant.PROFILE.SELECT_YOUR_MEDICAL_QUALIFICATION
                )}
                options={qualificationList}
                id='qaualification'
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.QUALIFICATION_TEXT
                )}
                isRequired={true}
                isError={false}
                errorMessage={t(AppConstant.ERROR.INCORRECT)}
                selectedOptions={qualificationOptions}
                onSelectChange={handleQualificationOptions}
              />
            </div>
            <div className={styles.profileFormCol}>
              <Dropdown
                defaultValue=''
                value={countryOfPractice}
                dropdownId='grouped-select'
                dropdownVariant='outlined'
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.COUNTRY_OF_PRACTICE_TEXT
                )}
                dropdownOptions={countryOfPracticeList}
                isError={false}
                errorMessage={t(AppConstant.ERROR.INCORRECT)}
                isRequired={true}
                handleDropDownChange={(e: SelectChangeEvent) =>
                  setCountryOfPractice(e.target.value)
                }
                placeholder={t(
                  AppConstant.AUTHENTICATION_PAGE.SELECT_COUNTRY_OF_PRACTICE
                )}
              />
            </div>
            <div className={styles.firstStepCtaWrapper}>
              <div className={styles.firstStepCta}>
                <ButtonComponent
                  variant='outlined'
                  buttonText={t(AppConstant.AUTHENTICATION_PAGE.NEXT_CTA)}
                  onPress={handleNext}
                  disabled={!nextCtaEnable}
                />
              </div>
            </div>
          </div>
        )}

        {activeStep === 2 && (
          <div className={styles.profileSecoundStep}>
            <div className={styles.profileFormCol}>
              <Input
                formControlVariant={'standard'}
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.SELECT_INTEREST_TEXT
                )}
                inputId={'selectInterests'}
                inputVariant={'outlined'}
                isRequired={true}
                isError={false}
                errorMessage={t(AppConstant.ERROR.INCORRECT)}
                handleChange={(e: eventType) =>
                  handleInterestSearch(e.target.value)
                }
                placeHolderText={t(AppConstant.AUTHENTICATION_PAGE.SEARCH_TEXT)}
              />
            </div>
            <div className={styles.profileFormCol}>
              <div className={styles.selectIntrest}>
                <label className={styles.siLabel}>
                  {t(AppConstant.AUTHENTICATION_PAGE.INTEREST_SELECTED_TEXT)}{' '}
                  {optionsSelected?.length
                    ? `(${optionsSelected?.length})`
                    : '(0)'}
                </label>
                <ChipList
                  options={interestList}
                  onSelectOption={setOptionsSelected}
                />
              </div>
            </div>
            <div className={styles.secoundStepCtaWrapper}>
              <div className={styles.secoundStepCta}>
                <ButtonComponent
                  variant='outlined'
                  buttonText={t(
                    AppConstant.AUTHENTICATION_PAGE.START_YOUR_JOURNEY_CTA
                  )}
                  disabled={optionsSelected?.length > 0 ? false : true}
                  onPress={startJourney}
                />
              </div>
            </div>
          </div>
        )}

        {(userLoading || authenticationLoading) && (
          <CircularLoader size={50} thickness={4} />
        )}
      </div>
      <div></div>
    </div>
  );
};
