import { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import {
  AppConstant,
  GAMIFICATION_ACTION_ID,
  getNotificationActionList,
  OTP_VERIFICATION_MEDIUM,
  RESEND_TIMER,
  translate,
  useAppDispatch,
  useAppSelector,
  validateContactDetail,
  validateOTP,
} from '@shalina-app/shared';

import { PersonalProps } from '.';
import {
  pageNames,
  TOAST_MESSAGE_HIDE_DURATION,
} from '../../../../../../app.constants';
import { appInsights } from '../../../../../../applicationInsight';
import {
  ButtonComponent,
  CircularLoader,
  HyperLink,
  Input,
  MobileInput,
  Toast
} from '../../../../../../components';
import { URLConstant } from '../../../../../../routes';
import {
  capitalizeFirstLetter,
  emailValidation,
  eventType,
  getTranslationFormat,
  inputTextValidation,
  mobileStartZeroValidation,
  mobileValidation,
  otpValidation,
  retrieveData,
  STORAGE_CONSTANTS,
  useNavigatePageUtils,
  UserInterface
} from '../../../../../../utils';
import { ButtonLoader } from '../../../../../communities';
import styles from './personal.module.scss';

export const Personal: React.FC<PersonalProps> = ({
  countryList,
  verificationFor,
  updatedMobile,
  updatedEmail,
  handleSaveProfileClick,
  profileData,
  isSaveLoader
}) => {
  const dispatch = useAppDispatch();
  const navigatePageUtils = useNavigatePageUtils();
  const { urls } = URLConstant;
  const t = translate();

  const authenticationLoading = useAppSelector(
    (state) => state?.authentication?.isLoading
  );
  const userUpdateLoading = useAppSelector((state) => state?.user?.isLoading);
  const isUpdatePointLoading = useAppSelector(
    (state) => state?.notification?.isLoading
  );
  const notificationList = useAppSelector(getNotificationActionList);

  const user: UserInterface | any = retrieveData(STORAGE_CONSTANTS.USER);

  const [isMobileCtaEnabled, setIsMobileCtaEnabled] = useState(false);
  const [isEmailCtaEnabled, setIsEmailCtaEnabled] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isMobileVeried, setIsMobileVerified] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(user?.mobileNumber);
  const [emailId, setEmailId] = useState(user?.emailId);
  const [countryCode, setCountryCode] = useState(user?.countryCode);
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [modalOpen, setModalOpen] = useState(false);
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(RESEND_TIMER);
  const [resendEnable, setResendEnable] = useState(false);
  const [isMobileVerifyClick, setIsMobileVerifyClick] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToast, setShowToast] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSubMessage, setToastSubMessage] = useState('');
  const [isVerificationToast, setIsVerificationToast] = useState(false);
  const [completedProfile, setCompletedProfile] = useState(false);
  const [isCancelEnabled, setCancelEnabled] = useState(false);
  const [initialValue, setInitialValue] = useState(false);
  const [mobileLength, setMobileLength] = useState('');
  const [countryFlag, setCountryFlag] = useState('');

  useEffect(() => {
    if (profileData && initialValue) {
      setInitialValue(false);
      verifyAndHandleSaveEnableDisable();
    }
  }, [profileData, initialValue]);

  useEffect(() => {
    setInitialValue(true);
  }, []);

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setResendEnable(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (!otp) {
      setIsError(true);
      setErrorMessage(t(AppConstant.ERROR.OTP_REQUIRED));
    } else {
      setIsError(false);
      setErrorMessage('');
    }
  }, [otp]);

  const onResendOTP = async () => {
    // Reset the timer and disable the Resend button
    setOtp('');
    setIsError(false);
    setErrorMessage('');
    setResendEnable(false);

    // Add logic here to trigger OTP resend
    // For example, you can make an API call to request a new OTP
    const payload = {
      countryCode: countryCode,
      mobileNumber: mobileNumber,
      emailId: emailId,
      mode: isMobileVerifyClick
        ? OTP_VERIFICATION_MEDIUM.WHATSAPP_VERIFICATION_MEDIUM
        : OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
    };

    await dispatch(validateContactDetail(payload));
    setTimer(RESEND_TIMER);
  };

  const handleMobileChange = (e: eventType) => {
    setMobileNumber(e?.target?.value);
    // if (e?.target?.value !== user?.mobileNumber) {
    //   if (!mobileValidation(e?.target?.value, mobileLength)) {
    //     setIsMobileCtaEnabled(true);
    //   } else {
    //     setIsMobileCtaEnabled(false);
    //   }
    //   setCancelEnabled(true);
    // } else {
    //   setIsMobileCtaEnabled(false);
    //   setCancelEnabled(false);
    // }
  };

  const handleCountryChange = async (e: eventType) => {
    setCountryCode(e.target.value);
    // if (
    //   e?.target?.value !== user?.countryCode &&
    //   !mobileValidation(mobileNumber, mobileLength)
    // ) {
    //   setIsMobileCtaEnabled(true);
    //   setCancelEnabled(true);
    // } else {
    //   setIsMobileCtaEnabled(false);
    //   setCancelEnabled(false);
    // }
  };

  const handleEmailChange = (e: eventType) => {
    const email = e?.target?.value;
    setEmailId(email);
    // setIsEmailVerifyLabelVisible(false);
    if (email !== user?.emailId && !emailValidation(email) && email) {
      setIsEmailCtaEnabled(true);
      setCancelEnabled(true);
    } else {
      setIsEmailCtaEnabled(false);
      setCancelEnabled(false);
    }
  };

  const handleFirstNameChange = (e: eventType) => {
    setFirstName(capitalizeFirstLetter(e.target.value));
    if (e?.target?.value?.toLowerCase() !== user?.firstName?.toLowerCase()) {
      // setIsSaveEnabled(true);
      setCancelEnabled(true);
    } else {
      setIsSaveEnabled(false);
      setCancelEnabled(false);
    }
  };

  const handleLastNameChange = (e: eventType) => {
    setLastName(capitalizeFirstLetter(e.target.value));
    if (e?.target?.value?.toLowerCase() !== user?.lastName?.toLowerCase()) {
      // setIsSaveEnabled(true);
      setCancelEnabled(true);
    } else {
      setIsSaveEnabled(false);
      setCancelEnabled(false);
    }
  };

  const savePersonalDetails = async () => {
    const req = {
      firstName: firstName,
      lastName: lastName,
      countryCode: countryCode,
      mobileNumber: mobileNumber,
      emailId: emailId
    };

    await handleSaveProfileClick(req);
    setIsSaveEnabled(false);
  };

  useEffect(() => {
    if (completedProfile) {
      sendNotificationCall();
      setCompletedProfile(false);
    }
  }, [completedProfile]);

  const sendNotificationCall = async () => {
    const action = notificationList?.find(
      (item: any) =>
        item?.field_unique_code === GAMIFICATION_ACTION_ID?.PROFILE_COMPLETION
    )?.title;

    const req = {
      action: action
    };
    // await dispatch(updatePointsWithNotification(req));
  };

  const handleMobileVerify = async () => {
    setIsMobileVerifyClick(true);
    const payload = {
      countryCode: countryCode,
      mobileNumber: mobileNumber,
      mode: OTP_VERIFICATION_MEDIUM.WHATSAPP_VERIFICATION_MEDIUM
    };
    const response = await dispatch(validateContactDetail(payload)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setIsVerificationToast(true);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));

      setOtp('');
      setModalOpen(true);
      setTimer(RESEND_TIMER);
      setResendEnable(false);
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response?.message));

      setModalOpen(false);
      setIsMobileVerifyClick(false);
    }
  };

  const handleEmailVerify = async () => {
    setIsMobileVerifyClick(false);
    const payload = {
      emailId: emailId,
      mode: OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM
    };
    const response = await dispatch(validateContactDetail(payload)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200
    ) {
      setIsVerificationToast(true);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
      // setToastSubMessage("OTP was sent successfully");

      // setOtp("");
      // setModalOpen(true);
      // setTimer(RESEND_TIMER);
      // setResendEnable(false);
      setOtp('');
      setModalOpen(true);
      setTimer(RESEND_TIMER);
      setResendEnable(false);
    } else {
      setModalOpen(false);

      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response?.message));
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleVerify = async () => {
    const payload = {
      emailId: emailId,
      countryCode: countryCode,
      mobileNumber: mobileNumber,
      mode: isMobileCtaEnabled
        ? OTP_VERIFICATION_MEDIUM.WHATSAPP_VERIFICATION_MEDIUM
        : OTP_VERIFICATION_MEDIUM.EMAIL_VERIFICATION_MEDIUM,
      otp: otp
    };
    const response = await dispatch(validateOTP(payload)).unwrap();
    if (
      response &&
      response?.status === 'SUCCESS' &&
      response?.statusCode === 200 &&
      response?.data
    ) {
      const updatedMobile = response?.data?.mobileNumber;
      const updatedEmail = response?.data?.emailId;
      setMobileNumber(updatedMobile);
      setEmailId(updatedEmail);
      setShowToast(true);
      setIsSuccess(true);
      setToastMessage(t(response?.message));
      // setToastSubMessage("Your personal information have been modified.");

      setIsError(false);
      setErrorMessage('');
      setModalOpen(false);

      setIsSaveEnabled(true);
      setIsMobileVerifyClick(false);
      if (isMobileCtaEnabled) {
        setIsMobileCtaEnabled(false);
      } else if (isEmailCtaEnabled) {
        setIsEmailCtaEnabled(false);
      }
    } else {
      setShowToast(true);
      setIsSuccess(false);
      setToastMessage(t(response?.message));

      setIsError(true);
      setErrorMessage(t(AppConstant.ERROR.OTP_INVALID_OR_EXPIRED));
      setIsMobileVerified(false);
      setIsEmailVerified(false);
      setIsSaveEnabled(false);
      setIsEmailCtaEnabled(false);
      setIsMobileCtaEnabled(false);
    }
  };

  const handleToastClose = () => {
    // if (isSuccess && isVerificationToast) {
    //   setOtp("");
    //   setModalOpen(true);
    //   setTimer(RESEND_TIMER);
    //   setResendEnable(false);
    // }
    setShowToast(false);
    // setIsVerificationToast(false);
  };

  const resetPersonalDetails = () => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setMobileNumber(user?.mobileNumber);
    setEmailId(user?.emailId);
    setCountryCode(user?.countryCode);
    setCancelEnabled(false);
    setIsSaveEnabled(false);
  };

  useEffect(() => {
    if (countryCode) {
      const country = countryList?.find(
        (item: any) => item?.value === countryCode
      );
      setMobileLength(country?.mobileLength);
      setCountryFlag(country?.icons);
      if (
        ((countryCode && countryCode !== user?.countryCode) ||
          (mobileNumber && mobileNumber !== user?.mobileNumber)) &&
        !mobileValidation(mobileNumber, mobileLength) && 
        !mobileStartZeroValidation(mobileNumber)
      ) {
        setIsMobileCtaEnabled(true);
        setCancelEnabled(true);
      } else {
        setIsMobileCtaEnabled(false);
        setCancelEnabled(false);
      }
    }
  }, [countryList, countryCode, mobileNumber]);

  useEffect(() => {
    verifyAndHandleSaveEnableDisable();
  }, [
    // mobileNumber,
    // emailId,
    firstName,
    lastName
    // isEmailCtaEnabled,
    // isMobileCtaEnabled,
    // mobileLength,
  ]);

  useEffect(() => {}, [isSaveEnabled]);

  const verifyAndHandleSaveEnableDisable = () => {
    if (
      (firstName &&
        user?.firstName?.toLowerCase() !== firstName?.toLowerCase() &&
        !isMobileCtaEnabled &&
        !isEmailCtaEnabled) ||
      (lastName &&
        user?.lastName?.toLowerCase() !== lastName?.toLowerCase() &&
        !isMobileCtaEnabled &&
        !isEmailCtaEnabled)
    ) {
      if (!inputTextValidation(firstName) && !inputTextValidation(lastName)) {
        setIsSaveEnabled(true);
      } else {
        setIsSaveEnabled(false);
      }
    } else {
      setIsSaveEnabled(false);
    }
  };

  return (
    <div className={styles.profileUpdateForm}>
      {isUpdatePointLoading && <CircularLoader size={50} thickness={4} />}
      <div className={styles.profileUpdateFormCol6}>
        <Input
          formControlVariant={'standard'}
          placeHolderText={t(AppConstant.PROFILE.ENTER_FIRST_NAME)}
          labelText={t(AppConstant.AUTHENTICATION_PAGE.FIRST_NAME_TEXT)}
          inputId={'firstName'}
          value={firstName}
          inputVariant={'outlined'}
          isRequired={true}
          isError={inputTextValidation(firstName) || !firstName}
          errorMessage={t(AppConstant.ERROR.ENTER_VALID_FIRST_NAME)}
          handleChange={(e: eventType) => handleFirstNameChange(e)}
        />
      </div>
      <div className={styles.profileUpdateFormCol6}>
        <Input
          formControlVariant={'standard'}
          placeHolderText={t(AppConstant.PROFILE.ENTER_LAST_NAME)}
          labelText={t(AppConstant.AUTHENTICATION_PAGE.LAST_NAME_TEXT)}
          inputId={'lastName'}
          value={lastName}
          inputVariant={'outlined'}
          isRequired={true}
          isError={inputTextValidation(lastName) || !lastName}
          errorMessage={t(AppConstant.ERROR.ENTER_VALID_LAST_NAME)}
          handleChange={(e: eventType) => handleLastNameChange(e)}
        />
      </div>
      <div className={styles.profileUpdateFormCol6}>
        {isMobileVeried ? (
          <span className={styles.verifiedLabel}>
            {getTranslationFormat(AppConstant.PROFILE.VERIFIED)}
            <svg
              width='17'
              height='10'
              viewBox='0 0 17 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M1 3L4.5 8.5L15.5 1' stroke='#04763F' strokeWidth='2' />
            </svg>
          </span>
        ) : null}
        <MobileInput
          defaultCountryCode={countryCode}
          placeHolderText={t(AppConstant.PROFILE.ENTER_MOBILE_NUMBER)}
          formControlVariant='standard'
          labelText={t(AppConstant.AUTHENTICATION_PAGE.MOBILE_NUMBER_TEXT)}
          inputId='mobileNumbar'
          inputVariant='outlined'
          isRequired={true}
          dropdownVariant='outlined'
          dropdownOptions={countryList}
          isError={
            mobileStartZeroValidation(mobileNumber) || mobileValidation(mobileNumber, mobileLength) || !mobileNumber
          }
          errorMessage={mobileStartZeroValidation(mobileNumber) ? t(AppConstant.ERROR.MOBILE_VALIDATION_FOR_START_ZERO) : t(AppConstant.ERROR.ENTER_VALID_MOBILE)}
          countryValue={countryCode}
          mobileValue={mobileNumber}
          handleChange={(e: eventType) => handleMobileChange(e)}
          handleCountryCodeChange={(e: any) => {
            mobileStartZeroValidation(mobileNumber);
            mobileValidation(mobileNumber, mobileLength);
            handleCountryChange(e);
          }}
          maxMobileLength={mobileLength}
          countryFlag={countryFlag}
        />
        <div className={styles.verifiedCta}>
          <button
            disabled={isMobileCtaEnabled ? false : true}
            onClick={handleMobileVerify}
          >
            {getTranslationFormat(AppConstant.PROFILE.VERIFY_CTA)}
          </button>
        </div>
      </div>
      <div className={styles.profileUpdateFormCol6}>
        {isEmailVerified && (
          <span className={styles.verifiedLabel}>
            {getTranslationFormat(AppConstant.PROFILE.VERIFIED)}
            <svg
              width='17'
              height='10'
              viewBox='0 0 17 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M1 3L4.5 8.5L15.5 1' stroke='#04763F' strokeWidth='2' />
            </svg>
          </span>
        )}
        <Input
          formControlVariant={'standard'}
          placeHolderText={t(AppConstant.PROFILE.ENTER_YOUR_EMAIL)}
          labelText={t(AppConstant.AUTHENTICATION_PAGE.EMAIL_TEXT)}
          inputId={'email'}
          inputVariant={'outlined'}
          isRequired={true}
          isError={emailValidation(emailId) || !emailId}
          errorMessage={t(AppConstant.ERROR.ENTER_VALID_EMAIL)}
          value={emailId}
          handleChange={(e: eventType) => handleEmailChange(e)}
        />
        <div className={styles.verifiedCta}>
          <button
            disabled={isEmailCtaEnabled ? false : true}
            onClick={handleEmailVerify}
          >
            {getTranslationFormat(AppConstant.PROFILE.VERIFY_CTA)}
          </button>
        </div>
      </div>
      <div className={styles.profileUpdateFormCol6}>
        {user?.referralCode && (
          <div className={styles.referralCode}>
            <label>
              {getTranslationFormat(AppConstant.PROFILE.REFERRAL_CODE)}
            </label>
            <div className={styles.referralCodeText}>{user?.referralCode}</div>
          </div>
        )}
      </div>
      <div className={styles.profileUpdateFormCol12}>
        <div className={styles.profileUpdateFormCtas}>
          <button
            disabled={isCancelEnabled ? false : true}
            onClick={resetPersonalDetails}
          >
            {getTranslationFormat(AppConstant.PROFILE.CANCEL_CTA)}
          </button>
          <button
            className={styles.save}
            disabled={isSaveEnabled ? false : true}
            onClick={savePersonalDetails}
          >
            {getTranslationFormat(AppConstant.PROFILE.SAVE_CTA)}
            <ButtonLoader loader={isSaveLoader} />
          </button>
        </div>
      </div>

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        className={styles.verificationModal}
      >
        <DialogContent>
          <div>
            <p className={styles.title}>
              {t(AppConstant.AUTHENTICATION_PAGE.VERIFICATION_TEXT)}
            </p>
            <div className={styles.infoText}>
              {t(
                AppConstant.AUTHENTICATION_PAGE.VERIFICATION_CODE_SENT_TO_TEXT
              )}
              <span>{isMobileVerifyClick ? mobileNumber : emailId}</span>
            </div>
            <div className={styles.verificationInput}>
              <Input
                formControlVariant={'standard'}
                placeHolderText={t(
                  AppConstant.AUTHENTICATION_PAGE.ENTER_VERIFICATION_CODE_TEXT
                )}
                labelText={t(
                  AppConstant.AUTHENTICATION_PAGE.ENTER_VERIFICATION_CODE_TEXT
                )}
                inputId={'verificationCode'}
                inputVariant={'outlined'}
                isRequired={true}
                isError={isError || otpValidation(otp)}
                errorMessage={
                  errorMessage || t(AppConstant.ERROR.ENTER_VALID_OTP)
                }
                value={otp}
                handleChange={(e: eventType) => setOtp(e.target.value)}
                maxLength={6}
              />
            </div>
            <div className={styles.timeLink}>
              {!resendEnable && timer ? (
                <div className={styles.time}>
                  {t(AppConstant.AUTHENTICATION_PAGE.TIME_REMAINING_TEXT)}{' '}
                  <span>{timer}</span> {t(AppConstant.COMMON.SECONDS_TEXT)}
                  {/* <span>{` ${Math.floor(timer / 60)}.${timer % 60}`}</span> */}
                </div>
              ) : (
                <span></span>
              )}
              <HyperLink
                handleClick={onResendOTP}
                hyperText={t(AppConstant.AUTHENTICATION_PAGE.RESEND_OTP_TEXT)}
                disabled={!resendEnable}
              />
            </div>
            <div className={styles.verificationCta}>
              <ButtonComponent
                variant='outlined'
                buttonText={getTranslationFormat(
                  AppConstant.PROFILE.VERIFY_CTA
                )}
                disabled={!otp || otpValidation(otp) || isError}
                onPress={handleVerify}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Toast
        showToast={showToast}
        isSuccess={isSuccess}
        message={toastMessage}
        subMessage={toastSubMessage}
        handleToastClose={handleToastClose}
      />
    </div>
  );
};
